import React from 'react';

function AnimatePing({ children, className }) {
  return (
    <div className={'relative ' + className}>
      <div className="h-3 w-3 rounded-full absolute top-0 right-0 bg-pink-600 animate-ping" />
      {children}
    </div>
  );
}
export default AnimatePing;
