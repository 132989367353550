import moment from 'moment-timezone';

const formatTime = (time, timeZone) => {
  let offset = timeZone ? moment().tz(timeZone)?.utcOffset() : 0;
  let _time = moment(time).local()?.utcOffset(offset).format('h:mm A');
  return _time === 'Invalid date'
    ? `${time} ${checkAndGetTimezone(timeZone)}`
    : `${_time} ${checkAndGetTimezone(timeZone)}`;
};

const subtractTime = (time, minutes, timeZone) => {
  let _time = moment(time, 'HH:mm a').subtract(minutes, 'minutes').format('hh:mm a');

  return _time === 'Invalid date'
    ? `${time} ${checkAndGetTimezone(timeZone)}`
    : `${_time} ${checkAndGetTimezone(timeZone)}`;
};

const formatDate = (date, format, timeZone = 'America/New_York') => {
  let offset = timeZone ? moment().tz(timeZone)?.utcOffset() : 0;
  return moment(date).local()?.utcOffset(offset).format(format);
};

const formatDateWithoutTz = (date, format) => {
  return moment(date).format(format);
};

const isToday = date => {
  return moment().isSame(moment(date).local(), 'day');
};

const current = () => {
  return formatDate(new Date(), 'YYYY-MM-DD');
};

export const checkAndGetTimezone = (timezone = 'America/New_York') => {
  return moment().tz(timezone)?.utcOffset() === moment()?.utcOffset()
    ? ''
    : `(${moment().tz(timezone).zoneAbbr()})`;
};

const onlyDate = date => {
  return date?.split('T')[0] || '';
};

export default {
  formatTime,
  subtractTime,
  checkAndGetTimezone,
  formatDate,
  isToday,
  onlyDate,
  current,
  formatDateWithoutTz,
};
