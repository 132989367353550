import React from 'react';
import { ModalBackground } from 'components/Shared';

function BagAdded(props) {
  const closeModal = () => {
    props.setAlert(false);
  };

  return (
    <div
      className={
        props.visible
          ? `fixed pin inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center z-20`
          : 'hidden'
      }
      onClick={() => {
        closeModal();
      }}>
      <ModalBackground />

      <div
        className="rounded-lg overflow-hidden shadow-md transform transition-all sm:max-w-lg sm:w-full"
        role="dialog"
        aria-modal="true"
        style={{ width: '400px' }}
        aria-labelledby="modal-headline">
        <div className="flex bg-white p-6  border-l-8 border-orange-brown flex-col">
          <h5 className="font-inter-medium">Bag Added</h5>
          <small className="text-dim-black font-inter-regular text-sm py-3">
            Additional bag has been added to your confirmed order for 40BK.{' '}
          </small>
          <small className="text-dim-black font-inter-regular text-sm">
            Please reconfirm order on details page.
          </small>

          <button className="btn-orange mb-3 mt-11">
            <small>Okay</small>
          </button>
        </div>
      </div>
    </div>
  );
}

export default BagAdded;
