const sortTableColumnWithoutAttributes = (arr, column, currentSort) => {
  return (
    Array.isArray(arr) &&
    arr.sort((a, b) => {
      let modifier = 1;
      if (currentSort === 'desc') modifier = -1;
      if (a[column] < b[column]) return -1 * modifier;
      if (a[column] > b[column]) return 1 * modifier;
      return 0;
    })
  );
};

const sortTableColumnWithoutAttributesWithCount = (arr, column, currentSort) => {
  return (
    Array.isArray(arr) &&
    arr.sort((a, b) => {
      let modifier = 1;
      if (currentSort === 'desc') modifier = -1;
      if (a[column].length < b[column].length) return -1 * modifier;
      if (a[column].length > b[column].length) return 1 * modifier;
      return 0;
    })
  );
};

const sortTableColumnWithAttributes = (arr, column, currentSort) => {
  return (
    Array.isArray(arr) &&
    arr.sort(({ attributes: a }, { attributes: b }) => {
      let modifier = 1;
      if (currentSort === 'desc') modifier = -1;
      if (a[column] < b[column]) return -1 * modifier;
      if (a[column] > b[column]) return 1 * modifier;
      return 0;
    })
  );
};

const getRestaurantList = currentUser => {
  let restaurantOrganizations = currentUser?.data?.attributes?.employees?.filter(
    employee => employee?.attributes?.organization_type === 'Restaurant'
  );

  let options = restaurantOrganizations?.map(restaurant => {
    return {
      value: restaurant.id,
      label: restaurant?.attributes?.organization_name,
      street: restaurant?.attributes?.organization_name,
      role: restaurant?.attributes?.role,
      softDeleted: restaurant?.attributes?.soft_deleted,
    };
  });

  return options;
};

const isEmpty = arr => {
  return arr?.length === 0;
};

const isPresent = arr => {
  return Array.isArray(arr) && arr?.length > 0;
};

export default {
  isEmpty,
  isPresent,
  sortTableColumnWithoutAttributes,
  sortTableColumnWithoutAttributesWithCount,
  sortTableColumnWithAttributes,
  getRestaurantList,
};
