import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

function SortButton(props) {
  return (
    <div className="relative pl-0.5" onClick={props.onClick}>
      <button className="absolute top-0 ">
        <FontAwesomeIcon icon={faSortUp} color={'#000'} />
      </button>
      <button>
        <FontAwesomeIcon icon={faSortDown} color={'#000'} />
      </button>
    </div>
  );
}
export default SortButton;
