import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlink } from '@fortawesome/free-solid-svg-icons';
const SoldOut = ({ soldOut, dark = false }) => {
  return (
    <strong
      className={`input-label font-inter-medium ${dark ? 'text-dark-purple' : 'text-white'} ${
        soldOut ? 'visible' : 'invisible'
      }`}>
      Sold Out
    </strong>
  );
};

export default SoldOut;
