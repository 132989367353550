import React, { useReducer, useContext, useEffect } from 'react';
import { StoreContext } from 'index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { toJS } from 'mobx';
import { ModalBackground } from 'components/Shared';
import { Dropdown } from 'components/Shared';
import object from 'utils/helpers/object';
import useEmployeeId from 'utils/helpers/user';
import { useParams } from 'react-router-dom';
import Icon from 'utils/helpers/icon';

function ViewEmails({ visible, closeModal, emails = [] }) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const { employeeId } = new useEmployeeId();
  const { id } = useParams();

  return (
    <div
      className={
        visible
          ? `fixed pin inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center z-30`
          : 'hidden'
      }>
      <ModalBackground />

      <div
        className="rounded-lg bg-white overflow-y-scroll shadow-md transform transition-all sm:max-w-lg sm:w-full animate-fade-in-down"
        role="dialog"
        aria-modal="true"
        style={{ width: '1290px', maxHeight: '90%' }}
        aria-labelledby="modal-headline">
        <div className="flex p-6 flex-col">
          <button
            onClick={() => {
              closeModal();
            }}>
            <FontAwesomeIcon
              icon={faTimes}
              color={'#ADB4C3'}
              size="lg"
              className="absolute top-4 right-4"
            />
          </button>
          <strong>Recipients</strong>
          <div className="d-col">
            {emails?.map(email => (
              <small className="input-label my-1">{email} </small>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewEmails;
