export default {
  login: () => `/sessions`,
  orderSummaries: () => `/restaurant_admin/shipments.json`,
  orders: () => `/restaurant_admin/shipments`,
  employees: () => `/restaurant_admin/employees`,
  forgotPassword: () => `/passwords/new`,
  menus: restaurant_id => `/restaurant_admin/menus?employee_id=${restaurant_id}`,
  optionCategories: (menuCategoryId, restaurant_id) =>
    `/restaurant_admin/menu_items/${menuCategoryId}?employee_id=${restaurant_id}`,
  resetPassword: () => '/passwords',
  bankingInformation: (userId, restaurantId) =>
    `restaurant_admin/banking_information/${userId}?employee_id=${restaurantId}`,
  accountingEmails: (userId, restaurantId) =>
    `restaurant_admin/accounting_emails/${userId}?employee_id=${restaurantId}`,
  salesReport: () => `/restaurant_admin/reports`,
  restaurantProfile: (userId, restaurantId) =>
    `/restaurant_admin/restaurant_profiles/${userId}?employee_id=${restaurantId}`,
  userProfile: (userId, restaurantId) =>
    `/restaurant_admin/profiles/${userId}?employee_id=${restaurantId}`, //to get the user profile info and to update notification settings
  profile: (userId, restaurantId) => `/profiles/${userId}?employee_id=${restaurantId}`, //to update the user profile
  orderXLS: () => `/restaurant_admin/orders.xlsx`,
  schedules: () => `/restaurant_admin/schedules`,
  eta: () => `/restaurant_admin/estimated_arrivals`,
  device: restaurantId => `/restaurant_admin/devices?employee_id=${restaurantId}`,
  fetchProfile: (email, restaurantId) => `/profiles/${email}?employee_id=${restaurantId}`,
  notifications: () => `/restaurant_admin/notifications`,
  cancellation: () => `/restaurant_admin/orders`,
  report: () => `/restaurant_admin/reports`,
  fortnightly: () => `/restaurant_admin/restaurant_profiles`,
};
