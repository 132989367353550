import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ModalBackground } from 'components/Shared';

function CateringOrderSummary(props) {
  const closeModal = () => {
    props.setAlert(false);
  };

  return (
    <div
      className={
        props.visible
          ? `fixed pin inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center z-20`
          : 'hidden'
      }>
      <ModalBackground />

      <div
        className="rounded-lg overflow-hidden shadow-md transform transition-all sm:max-w-lg sm:w-full overflow-y-auto"
        role="dialog"
        aria-modal="true"
        style={{ width: '500px', maxHeight: '500px' }}
        aria-labelledby="modal-headline">
        <div className="flex bg-white p-6 flex-col">
          <button
            onClick={() => {
              closeModal();
            }}>
            <FontAwesomeIcon
              icon={faTimes}
              color={'#ADB4C3'}
              size="lg"
              className="absolute top-4 right-4"
            />
          </button>

          <h5 className="font-inter-regular">Order summary</h5>

          <table className="w-full table-fixed">
            <thead className="bg-white">
              <tr>
                <th scope="col" className="w-1/6 pt-3 text-left tracking-wider">
                  <small className="text-xs text-black tracking-wider font-inter-regular font-normal">
                    Qty
                  </small>
                </th>

                <th scope="col" className="w-1/2 pt-3 text-left tracking-wider">
                  <small className="text-xs text-black font-inter-regular font-normal">
                    Item Name
                  </small>
                </th>

                <th scope="col" className="w-1/2 pt-3 text-left tracking-wider">
                  <small className="text-xs text-black font-inter-regular font-normal">Item</small>
                </th>
              </tr>
            </thead>

            <tbody className="bg-white">
              {props.data?.data?.map(
                ({ attributes: { order_items, subtotal, grand_total, hst, tip } }, index) => (
                  <>
                    {order_items.map(({ attributes: { quantity, menu_item, total_price } }) => (
                      <tr key={index} className="cursor-pointer">
                        <td className="py-2 whitespace-nowrap align-top">
                          <small className="text-xs font-inter-regular text-dark-gray">
                            {quantity}
                          </small>
                        </td>

                        <td className="py-2 align-top">
                          <small className="text-sm font-inter-regular text-dark-gray break-normal">
                            {menu_item?.display_name || menu_item?.title}
                          </small>
                        </td>

                        <td className="py-2 align-top">
                          <p className="text-sm text-dark-gray font-inter-regular break-normal">
                            ${parseFloat(total_price).toFixed(2)}
                          </p>
                        </td>
                      </tr>
                    ))}

                    <div className="mt-5" />

                    <tr>
                      <td />

                      <td className="pt-2 align-top">
                        <small className="text-sm text-dark-gray font-inter-semibold">
                          Food total:
                        </small>
                      </td>

                      <td className="pt-2 align-top">
                        <small className="text-sm text-dark-gray font-inter-semibold">
                          ${parseFloat(subtotal).toFixed(2)}
                        </small>
                      </td>
                    </tr>

                    <tr>
                      <td />

                      <td className="pt-2 align-top">
                        <small className="text-sm text-dark-gray font-inter-semibold">Tax:</small>
                      </td>

                      <td className="pt-2 align-top">
                        <small className="text-sm text-dark-gray font-inter-semibold">
                          ${parseFloat(hst).toFixed(2)}
                        </small>
                      </td>
                    </tr>

                    <tr>
                      <td />

                      <td className="pt-2 align-top">
                        <small className="mt-2 text-sm font-inter-semibold">Total:</small>
                      </td>

                      <td className="pt-2 align-top">
                        <small className="mt-2 text-sm font-inter-semibold">
                          ${parseFloat(grand_total).toFixed(2)}
                        </small>
                      </td>
                    </tr>
                  </>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CateringOrderSummary;
