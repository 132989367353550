import moment from 'moment';
import Validation from 'utils/helpers/validation';
import dateTime from './dateTime';

//For the restaurant web app
const extractETA = data => {
  let now = moment(new Date());
  let eta = moment(data?.pickup_eta).utc();
  let duration = Math.floor(moment.duration(eta.diff(now)).asMinutes());

  if (data?.delivered) return 'Completed';
  if (data?.picked_up) return 'In Transit';
  if (data?.pickup_delay_reason && data?.pickup_delay_reason?.replace(/\s/g, '') !== '')
    return data?.pickup_delay_reason;
  if (duration < 5) return 'Arriving';
  else return `ETA ${duration} Minutes`;
};

const etaTooltip = data => {
  if (data?.delivered) return 'The delivery is complete.';
  if (data?.picked_up) return 'The driver has received the meal and is on route to the client.';
  if (data?.pickup_delay_reason && data?.pickup_delay_reason?.replace(/\s/g, '') !== '')
    return data?.pickup_delay_reason;
  else return `The estimated arrival time of the delivery driver.`;
};

const filterOrdersPrepare = (search, data) => {
  return data?.data?.filter(item => {
    return (
      item.attributes?.bag_number === Number(search) ||
      item?.attributes?.order_items?.length === Number(search) ||
      item.attributes?.permanent_instructions?.toLowerCase().includes(search.toLowerCase()) ||
      item.attributes?.user_name.toLowerCase().includes(search.toLowerCase()) ||
      item.attributes.order_items.filter(orderItem => {
        return orderItem?.attributes?.category?.toLowerCase().includes(search.toLowerCase());
      }).length > 0 ||
      item.attributes.order_items.filter(orderItem => {
        return orderItem?.attributes?.menu_item?.title
          ?.toLowerCase()
          .includes(search.toLowerCase());
      }).length > 0 ||
      item.attributes.order_items.filter(orderItem => {
        return orderItem?.attributes?.special_instructions
          ?.toLowerCase()
          .includes(search.toLowerCase());
      }).length > 0 ||
      item.attributes.order_items.filter(orderItem => {
        return (
          orderItem?.attributes?.order_item_options?.filter(option =>
            option?.attributes?.option_name?.toLowerCase().includes(search.toLowerCase())
          ).length > 0
        );
      }).length > 0
      // || item.attributes?.order_items.filter(orderItem => {
      //   return Number(orderItem?.attributes?.price) === Number(search);
      // }).length > 0
    );
  });
};

const priceWithOptions = (basePrice, options) => {
  let total = Number(basePrice);
  options?.map(({ attributes: { price } }) => (total += Number(price)));
  return Validation.priceFormatter(total);
};

const simplePriceWithOptions = (basePrice, options) => {
  let total = Number(basePrice);
  options?.map(({ attributes: { price } }) => (total += Number(price)));
  return total;
};

const getOpacity = status => {
  return status === 'cancelled' ? 'opacity-50' : '';
};

const notificationDescription = (notification, timeZone) => {
  switch (notification?.type) {
    case 'new_order':
      return `A bag has been added to your order for ${notification?.data?.destination_code} on ${
        notification?.data?.date
      } - ${dateTime.formatTime(notification?.data?.cutoff, timeZone)}.`;
    case 'cancel_order':
      return `A bag has been removed from your order for ${
        notification?.data?.destination_code
      } on ${notification?.data?.date} - ${dateTime.formatTime(
        notification?.data?.cutoff,
        timeZone
      )}.`;
    case 'bag_added':
      return `Additional bag is added to ${notification?.data?.destination_code} on ${
        notification?.data?.date
      } - ${dateTime.formatTime(notification?.data?.cutoff, timeZone)}.`;
    case 'order_preview':
      return `Your order for ${notification?.data?.destination_code} on ${
        notification?.data?.date
      } - ${dateTime.formatTime(notification?.data?.cutoff, timeZone)} has been locked. \n\n\n
       Users can no longer make changes to this order but can add additional bags`;
    case 'order_finalize':
      return `Your order for ${notification?.data?.destination_code} on ${
        notification?.data?.date
      } - ${dateTime.formatTime(
        notification?.data?.cutoff,
        timeZone
      )} has been finalized. Please navigate to the orders detail page to confirm.`;
    case 'cancellation_request':
      return `There is a cancellation request for an order for  ${
        notification?.data?.destination_code
      } on ${notification?.data?.date} - ${dateTime.formatTime(
        notification?.data?.cutoff,
        timeZone
      )}.`;
    case 'all_shipments_published':
      return 'Your Uncatering schedule for next week is available for review, please confirm your schedule as soon as possible.';
    case 'additional_shipment_added':
      return 'An additional Uncatering order has been added to your schedule, please confirm the order as soon as possible.';
    case 'unconfirmed_shipment_reminder':
      return 'You currently have unconfirmed Uncatering orders for the upcoming week, please confirm the orders as soon as possible.';
    case 'shipment_removed':
      return `The Uncatering order on ${dateTime.formatDateWithoutTz(
        notification?.data?.date,
        'MMMM D, yyyy'
      )} for ${
        notification?.data?.destination_code
      } has been cancelled by the client. We have therefore updated your schedule to reflect these changes. We apologize for any inconvenience.`;
    case 'expecte_bags_changed':
      return `The expected bag count for your Uncatering order on ${dateTime.formatDateWithoutTz(
        notification?.data?.date,
        'MMMM D, yyyy'
      )} for ${
        notification?.data?.destination_code
      } has been updated based on new information from the client. Please review your updated schedule as soon as possible.`;

    case 'hungerhub_catering_order_added':
      return `A new hungerhub catering order has been added for ${dateTime.formatDateWithoutTz(
        notification?.data?.date,
        'MMMM D, yyyy'
      )}, please confirm the order as soon as possible.`;
    case 'new_catering_order':
      return `A new catering order has been added for ${dateTime.formatDateWithoutTz(
        notification?.data?.date,
        'MMMM D, yyyy'
      )}, please confirm the order as soon as possible.`;
    case 'hungerhub_catering_order_cancellation':
      return `The hungerhub catering order for ${dateTime.formatDateWithoutTz(
        notification?.data?.date,
        'MMMM D, yyyy'
      )}, has been cancelled by the client. We have therefore updated your schedule to reflect these changes. We apologize for any inconvenience. `;
    case 'direct_catering_order_cancellation':
      return `The catering order for ${dateTime.formatDateWithoutTz(
        notification?.data?.date,
        'MMMM D, yyyy'
      )}, has been cancelled by the client. We have therefore updated your schedule to reflect these changes.`;
    case 'hungerhub_catering_order_confirmation':
      return `Today's hungerhub catering order for ${notification?.data?.destination_code} will be picked up at ${notification?.data?.pickup_time}. Please confirm the order as soon as possible. `;
    case 'direct_catering_order_confirmation':
      return `Today's catering order for ${notification?.data?.destination_code} will be picked up at ${notification?.data?.pickup_time}. Please confirm the order as soon as possible. `;
    default:
      return 'An order update';
  }
};

const getBusinessSource = notificationType => {
  return notificationType?.includes('hungerhub') ? 'hungerhub' : 'restaurant';
};

const sortByCutoff = shipments => {
  return shipments?.sort((a, b) => moment(a.cutoff).diff(b.cutoff));
};

const getLabelName = item => {
  let label = item?.menu_item?.display_name || item?.menu_item?.title;

  if (item?.order_item_options.length > 0)
    label +=
      ' - ' + item?.order_item_options.map(option => option.attributes.option_name).join(', ');

  return label;
};

const getLabel = (attributes, sortedData) => {
  return attributes.notes != '' && attributes.notes.split('#@').length + 1 == attributes.quantity
    ? `${attributes.quantity} - ${getLabelName(attributes)}`
    : `${
        attributes.notes != ''
          ? attributes.notes.split('#@').length + 1
          : attributes.notes.split('#@').length
      }-${attributes.quantity} - ${getLabelName(attributes)} -  ${sortedData.destination_code}`;
};

const showExtraLabel = (attributes, sortedData) => {
  return (
    <>
      <>
        {attributes?.notes == '' && attributes?.quantity == 1 && (
          <p className="overflow-ellipsis text-regal-blue overflow-hidden mb-2 text-xs lg:text-xs font-inter-regular">
            {`${attributes.quantity} - ${getLabelName(attributes)}`}
          </p>
        )}
      </>

      {attributes?.notes?.split('#@').length < attributes?.quantity && (
        <p className="overflow-ellipsis text-regal-blue overflow-hidden mb-2 text-xs lg:text-xs font-inter-regular">
          {getLabel(attributes, sortedData)}
        </p>
      )}
    </>
  );
};

export default {
  extractETA,
  filterOrdersPrepare,
  priceWithOptions,
  simplePriceWithOptions,
  getOpacity,
  notificationDescription,
  sortByCutoff,
  getLabelName,
  getLabel,
  showExtraLabel,
  etaTooltip,
  getBusinessSource,
};
