import { useCallback, useContext } from 'react';
import { StoreContext } from 'index';
import useEmployeeId from 'utils/helpers/user';
import { useHistory, useLocation } from 'react-router-dom';
import dateTime from 'utils/helpers/dateTime';
import object from 'utils/helpers/object';
import { useQueryMethods } from './useQueryMethods';
import { errorHandler } from 'utils/middlewares/errorHandler';

export const useNotificationMethods = () => {
  const store = useContext(StoreContext);
  let { employeeId } = useEmployeeId();
  const { getAllParams, resourceId } = useQueryMethods();
  const history = useHistory();

  const unreadNotifications = useCallback(employeeId => {
    store.notificationStore
      .getNotifications(
        {
          employee_id: employeeId,
          date: dateTime.formatDate(new Date(), 'YYYY-MM-DD'),
          per_page: 5,
          page: 1,
          status: 'unread',
        },
        true
      )
      .then(response => {
        if (object.hasKey(response?.notifications, dateTime.current())) {
          let notifications = response?.notifications[dateTime.current()];

          if (notifications?.length > 0) {
            let recentNotification = notifications[0];
            recentNotification.notification_for = recentNotification?.type;
            recentNotification.notification_id = recentNotification?.id;
            store.orderStore.setActionCablePayload(recentNotification);
          }
        }
      });
  }, []);

  const acceptDeclineCancellation = useCallback((id, approve) => {
    store.userStore.setLoader(true);
    store.orderStore
      .acceptDeclineCancellation(id, { employee_id: employeeId }, approve)
      .then(response => {
        store.orderStore
          .getOrdersPrepare({ employee_id: employeeId }, resourceId())
          .then(response => {
            store.userStore.setLoader(false);
          });

        errorHandler({ title: response?.data?.message });
      });
  }, []);

  return { unreadNotifications, acceptDeclineCancellation };
};
