const roleTooltip = () => {
  return (
    <div>
      Owner: Has full access to the restaurant portal. <br /> <br />
      Manager: Has access to everything except accounting information. This includes accessing
      scheduled orders and managing the restaurant's team. <br /> <br />
      Employee: Can only view the daily order summaries. This includes accepting orders and
      contacting customer support.
      <br /> <br />
      Accountant: Can only manage the restaurant's accounting information. This includes exporting
      order history and managing banking information. <br /> <br />
    </div>
  );
};

export default {
  roleTooltip,
};
