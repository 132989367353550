import React, { useEffect, useState, useContext } from 'react';
import Screen from 'utils/helpers/ScreenSize';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { StoreContext } from 'index';
import { CustomDatePicker } from 'components/DatePicker';
import { Bags } from 'assets/img';
import { Counter } from 'components/Counter';
import { HeedBadge } from 'components/HeedBadge';
import { CateringOrderTable } from 'components/Table';
import { CateringOrderSummary } from 'components/Alerts';
import { DestinationCodeSelect, AnimatePing, ETA } from 'components/Shared';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import useEmployeeId from 'utils/helpers/user';
import moment from 'moment';
import { CateringOrderMobile } from 'components/Mobile';
import { useFreshChatMethods } from 'hooks/useFreshChatMethods';
import { useQueryMethods } from 'hooks/useQueryMethods';
import dateTime from 'utils/helpers/dateTime';

function CateringOrderDetail(props) {
  const [orderSummary, showOrderSummary] = useState(false);
  const { getParam, resourceId } = useQueryMethods();
  const [selectedDate, setDate] = useState(new Date(moment(getParam('date')).local()));
  const [searchText, setSearchText] = useState('');
  const { employeeId, timeZone } = new useEmployeeId();
  const screenType = Screen.ScreenType();
  const store = useContext(StoreContext);
  const orderStore = toJS(store.orderStore);
  const userStore = toJS(store.userStore);
  const { resetFreshChat } = useFreshChatMethods();
  let params = { employee_id: employeeId };
  const { ordersPrepare, currentPath, eta } = orderStore;
  const isETAavailable = eta?.pickup_started || eta?.delivered || eta?.picked_up;
  const isShipmentCompleted = ordersPrepare?.status === 'completed';

  useEffect(() => {
    window?.fcWidget?.destroy();
    store.orderStore.setCurrentPath({ pathName: '/catering-order' });

    store.userStore.setLoader(true);
    getOrders();
    resetFreshChat();
    getETA();
    const interval = setInterval(() => {
      getETA();
    }, 120000);

    return () => {
      clearInterval(interval);
      store.orderStore.setCurrentPath(null);
    };
  }, []);

  const getETA = () => {
    store.orderStore.getETA(resourceId(), params);
  };

  const getOrders = () => {
    if (employeeId)
      store.orderStore.getOrdersPrepare(params, resourceId()).then(response => {
        store.orderStore.getOrderSummaries(
          {
            employee_id: employeeId,
            date: response?.data?.meal_date,
            service: 'uncatering',
          },
          timeZone
        );
        store.orderStore.getHungerhubCateringSummaries({
          employee_id: employeeId,
          date: response?.data?.meal_date,
          service: 'odc',
        });
        store.userStore.setLoader(false);
      });
  };

  const onSearchChange = text => {
    setSearchText(text);
  };

  const confirmShipmentFirst = () => {
    store.userStore.setLoader(true);

    store.scheduleStore
      .updateSchedule(resourceId(), resourceId(), true, userStore?.defaultRestaurant?.value)
      .then(response => {
        store.userStore.setLoader(false);
        getOrders();
      });
  };

  const confirmShipment = () => {
    store.userStore.setLoader(true);
    store.orderStore.confirmShipment(params, resourceId()).then(response => {
      getOrders();
      store.userStore.setLoader(false);
    });
  };

  let withSentStatus = ordersPrepare?.data?.filter(
    ({ attributes: { status, shipment_acceptance_status } }) =>
      status === 'sent' || shipment_acceptance_status === null
  );

  const getTotalQuantity = ordersPrepare => {
    let quantity = 0;
    ordersPrepare?.data?.forEach(item => {
      item.attributes.order_items.forEach(order_item => {
        quantity += order_item.attributes.quantity;
      });
    });

    return quantity;
  };

  let statusPill = (
    <div className="w-full lg:w-auto d-row">
      {ordersPrepare?.status === 'confirmed' && isETAavailable ? (
        <div className="animate-fade-in-down d-row">
          <ETA eta={eta} />
        </div>
      ) : (
        <div className="animate-fade-in-down d-row">
          {withSentStatus?.length > 0 ? (
            <AnimatePing>
              <HeedBadge text="Alert" />
            </AnimatePing>
          ) : (
            <HeedBadge text={ordersPrepare?.status} className="capitalize" />
          )}
        </div>
      )}

      {ordersPrepare?.pickup && !isShipmentCompleted && !isETAavailable && (
        <small className="input-label font-inter-medium ml-2">
          Pick up time: {dateTime.formatTime(ordersPrepare?.pickup, timeZone)}
        </small>
      )}
    </div>
  );

  return (
    <div className="bg-white text-left min-h-screen d-col">
      <div className="flex flex-wrap">
        <div className="w-full">
          <div className="relative d-col min-w-0 break-words bg-white w-full mb-6 rounded shadow-md border mt-2">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div className={'block animate-fade-in-down'} id="link1">
                  <div>
                    {screenType.isTabletOrDesktop ? (
                      <div className="d-row justify-between">
                        <div className="d-row">
                          <div className="d-col w-1/2 lg:w-56 z-5">
                            <small className="font-inter-regular text-dark-gray text-xs ">
                              Order
                            </small>
                            <DestinationCodeSelect
                              destinationCode={ordersPrepare?.destination_code}
                              cutoff={ordersPrepare?.cutoff}
                              key={ordersPrepare}
                            />
                          </div>

                          <div className="d-col px-4">
                            <small className="font-inter-regular text-dark-gray text-xs ">
                              Date
                            </small>
                            <CustomDatePicker
                              selectedDate={new Date(moment(ordersPrepare?.meal_date).local())}
                              disabled
                            />
                          </div>
                        </div>

                        <div className="d-row">
                          <Counter
                            label="Items"
                            count={getTotalQuantity(ordersPrepare)}
                            icon={Bags}
                          />
                          <Counter
                            label="Cutlery"
                            count={ordersPrepare?.cutlery_count * getTotalQuantity(ordersPrepare)}
                            icon={faUtensils}
                            fontAwesomeIcon
                          />
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="d-row justify-between">
                          <div className="d-col lg:w-56 z-5">
                            <DestinationCodeSelect
                              destinationCode={ordersPrepare?.destination_code}
                              cutoff={ordersPrepare?.cutoff}
                              key={ordersPrepare}
                            />
                          </div>

                          {withSentStatus?.length > 0 && (
                            <AnimatePing className="w-24 mt-3">
                              <HeedBadge text="Alert" />
                            </AnimatePing>
                          )}
                        </div>

                        <div className="d-row justify-between mt-3">
                          <div className="w-1/2">
                            <CustomDatePicker
                              selectedDate={new Date(moment(ordersPrepare?.meal_date).local())}
                              disabled
                            />
                          </div>
                        </div>

                        <div className="d-row justify-between mt-2">
                          <div className="w-1/2">
                            <Counter
                              label="Items"
                              count={getTotalQuantity(ordersPrepare)}
                              icon={Bags}
                            />
                          </div>
                          <div className="w-1/2 d-row justify-end">
                            <Counter
                              label="Cutlery"
                              count={ordersPrepare?.cutlery_count * getTotalQuantity(ordersPrepare)}
                              icon={faUtensils}
                              fontAwesomeIcon
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="d-row">
                      {ordersPrepare?.source_of_business == 'restaurant' ? (
                        <div className="d-col">
                          <span className="input-label text-dark-purple">
                            Customer Name:{' '}
                            <span className="input-label">{ordersPrepare.user_name}</span>
                          </span>

                          <span className="input-label text-dark-purple py-2">
                            Customer Address:{' '}
                            <span className="input-label">{ordersPrepare.address}</span>
                          </span>

                          <span className="input-label text-dark-purple">
                            Customer Phone Number:{' '}
                            <span className="input-label">{ordersPrepare.phone}</span>
                          </span>
                        </div>
                      ) : (
                        statusPill
                      )}
                    </div>

                    <div className="d-col flex-col-reverse md:flex-row md:items-center w-full justify-between mt-5">
                      <div className="flex flex-col md:flex-row">
                        {ordersPrepare?.source_of_business == 'restaurant' && statusPill}
                      </div>

                      <div className="items-start w-full md:w-auto lg:w-auto d-row">
                        <button
                          className="btn-purple px-4 w-1/3 md:w-auto"
                          onClick={() => showOrderSummary(true)}>
                          <small>Summary</small>
                        </button>
                      </div>
                    </div>

                    {screenType.isMobile ? (
                      <CateringOrderMobile
                        data={ordersPrepare}
                        handlePageClick={() => {}}
                        withSentStatus={withSentStatus?.length > 0}
                        currentPath={currentPath}
                        eta={eta}
                        searchText={searchText}
                        confirmShipment={() => confirmShipment()}
                        confirmShipmentFirst={confirmShipmentFirst}
                      />
                    ) : (
                      <CateringOrderTable
                        data={ordersPrepare}
                        handlePageClick={() => {}}
                        withSentStatus={withSentStatus?.length > 0}
                        searchText={searchText}
                        currentPath={currentPath}
                        eta={eta}
                        confirmShipment={() => confirmShipment()}
                        confirmShipmentFirst={confirmShipmentFirst}
                      />
                    )}

                    <CateringOrderSummary
                      setAlert={() => showOrderSummary(false)}
                      visible={orderSummary}
                      data={ordersPrepare}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(CateringOrderDetail);
