const isLoggedIn = () => {
  const authToken = localStorage.getItem('auth_token');
  return authToken ? true : false;
};

const setAccessToken = token => {
  localStorage.setItem('auth_token', token);
};

const getAccessToken = () => {
  return localStorage.getItem('auth_token') || null;
};

const removeKey = key => {
  localStorage.removeItem(key);
};

export default { isLoggedIn, setAccessToken, getAccessToken, removeKey };
