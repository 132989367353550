import React, { useEffect } from 'react';
import './App.css';
import { Header } from '../src/components/Header';
import { SideBar } from 'components/SideBar';

function App() {
  return (
    <div className="App">
      {/* <Header />
      <div className=" h-screen">
        <SideBar />
      </div> */}
    </div>
  );
}

export default App;
