import React from 'react';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

function Tooltip({ tooltipText, id, custom, children }) {
  return (
    <div className="flex px-1 cursor-pointer">
      <FontAwesomeIcon
        icon={faExclamationCircle}
        color={'#000'}
        size="sm"
        data-tip={tooltipText}
        className="break-words"
        data-for={id}
      />

      {custom ? (
        <ReactTooltip
          id={id}
          type="success"
          effect="float"
          multiline={true}
          className="tooltip normal-case"
          textColor="#fff"
          place="top"
          backgroundColor="#000">
          {children}
        </ReactTooltip>
      ) : (
        <ReactTooltip
          id={id}
          type="success"
          effect="float"
          multiline={true}
          className="tooltip normal-case"
          textColor="#fff"
          place="top"
          backgroundColor="#000"
        />
      )}
    </div>
  );
}
export default Tooltip;
