import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { StoreContext } from 'index';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { toJS } from 'mobx';
import useEmployeeId from 'utils/helpers/user';
import dateTime from 'utils/helpers/dateTime';

function ActionCableWrapper({ children }) {
  const store = useContext(StoreContext);
  const { employeeId } = new useEmployeeId();

  const orderStore = toJS(store.orderStore);
  let { currentPath, actionCablePayload } = orderStore;

  const handleReceived = message => {
    console.log('message', message);
    store.orderStore.setActionCablePayload(null);
    setTimeout(() => {
      if (actionCablePayload === null)
        store.notificationStore
          .getNotifications(
            {
              employee_id: employeeId,
              date: dateTime.formatDate(new Date(), 'YYYY-MM-DD'),
              per_page: 5,
              page: 1,
              status: 'unread',
            },
            true
          )
          .then(response => {
            store.orderStore.setActionCablePayload(message);
          });
    }, 2000);
  };

  return (
    <ActionCableConsumer
      channel="Noticed::NotificationChannel"
      onReceived={handleReceived}
      onDisconnected={() => console.log('Disconnected')}
      onConnected={() => console.log('Connected')}>
      {children}
    </ActionCableConsumer>
  );
}

export default observer(ActionCableWrapper);
