import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

function SearchBar({ onChange }) {
  return (
    <div className="relative w-3/4 md:w-80 border shadow-sm rounded-lg overflow-hidden h-11  mr-2">
      <input
        type="text"
        className="h-11 w-full pl-5 focus:outline-none bg-light-gray"
        placeholder="Search"
        onChange={text => onChange(text)}
        onChange={e => onChange(e.target.value)}
      />
      <div className="absolute top-2 right-3">
        <FontAwesomeIcon icon={faSearch} color={'#000'} size="sm" />
      </div>
    </div>
  );
}

export default SearchBar;
