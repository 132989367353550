import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { NotFound } from 'components/Shared';
import { observer } from 'mobx-react';
import Screen from 'utils/helpers/ScreenSize';
import array from 'utils/helpers/array';
import dateTime from 'utils/helpers/dateTime';
import Validation from 'utils/helpers/validation';
import { ViewEmails } from 'components/Alerts';
import object from 'utils/helpers/object';
import ReactPaginate from 'react-paginate';

function ReportsTable({ data, handlePageClick }) {
  const history = useHistory();
  const screenType = Screen.ScreenType();
  const [viewEmails, setViewEmails] = useState(false);

  const pageClick = data => {
    handlePageClick(data.selected + 1);
  };

  return (
    <div className="flex flex-col mt-4 w-full">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 pb-48">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
            <table className="w-full table-fixed">
              <thead className="bg-white">
                <tr>
                  <th scope="col" className="t-head text-left pl-1 md:pl-3 d-row">
                    <small className="t-row">Report date</small>
                  </th>

                  <th scope="col" className="t-head">
                    <small className="t-row">Start date</small>
                  </th>

                  <th scope="col" className="t-head pl-3">
                    <small className="t-row">End date</small>
                  </th>

                  <th scope="col" className="t-head pl-3">
                    <small className="t-row">Payment date</small>
                  </th>

                  <th scope="col" className="t-head pl-3">
                    <small className="t-row">Payment total</small>
                  </th>

                  <th scope="col" className="t-head pl-3">
                    <small className="t-row">Emails</small>
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white divide-y divide-gray-200">
                {array.isPresent(data?.reports) &&
                  data?.reports?.map(
                    ({
                      id,
                      report_date,
                      report_end_date,
                      report_start_date,
                      emails,
                      net_deposit,
                      payment_date,
                    }) => (
                      <tr key={id} className="odd:bg-table-row hover:bg-gray-100 cursor-pointer">
                        <td className="py-3 align-middle break-words text-left pl-0.5 md:pl-3">
                          <small className="text-xs text-dark-gray font-inter-regular capitalize">
                            {dateTime.onlyDate(report_date)}
                          </small>
                        </td>

                        <td className="py-3 align-middle break-words text-left">
                          <small className="text-xs text-dark-gray font-inter-regular">
                            {dateTime.onlyDate(report_start_date)}
                          </small>
                        </td>

                        <td className="py-3 align-middle break-words text-left pl-3">
                          <small className="text-xs text-dark-gray font-inter-regular">
                            {dateTime.onlyDate(report_end_date)}
                          </small>
                        </td>

                        <td className="py-3 align-middle break-words text-left pl-3">
                          <small className="text-xs text-dark-gray font-inter-regular">
                            {dateTime.onlyDate(payment_date)}
                          </small>
                        </td>

                        <td className="py-3 align-middle break-words text-left pl-3">
                          <small className="text-xs text-dark-gray font-inter-regular">
                            {Validation.priceFormatter(net_deposit)}
                          </small>
                        </td>

                        <td className="py-3 align-middle break-words text-left pl-3">
                          <p
                            title={emails}
                            className="text-xs text-dark-gray font-inter-regular truncate">
                            {emails}
                          </p>
                          <button
                            onClick={() => setViewEmails({ id, emails })}
                            className="text-xxs text-blue-600 hover:text-blue-300 input-label">
                            View all
                          </button>
                        </td>
                      </tr>
                    )
                  )}
                <ViewEmails
                  visible={object.isPresent(viewEmails)}
                  emails={viewEmails?.emails}
                  closeModal={() => {
                    setViewEmails(null);
                  }}
                />
              </tbody>
            </table>

            {data?.pagy && (data?.pagy?.next !== null || data?.pagy?.prev !== null) && (
              <div className="mt-4 text-primary-color">
                <ReactPaginate
                  previousLabel={'Previous'}
                  nextLabel={'Next'}
                  breakLabel={<span className="gap">...</span>}
                  pageCount={data?.pagy?.pages}
                  onPageChange={pageClick}
                  forcePage={data?.pagy?.page - 1 > 0 ? data?.pagy?.page - 1 : 0}
                  containerClassName={'pagination'}
                  previousLinkClassName={'previous_page'}
                  nextLinkClassName={'next_page'}
                  disabledClassName={'disabled'}
                  activeClassName={'active'}
                />
              </div>
            )}

            <NotFound data={data?.reports} message="No Reports found" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(ReportsTable);
