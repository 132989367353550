import React, { useContext, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Screen from 'utils/helpers/ScreenSize';
import FreshChat from 'react-freshchat';
import { createBrowserHistory } from 'history';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { StoreContext } from 'index';
import { ActionCableAlert } from 'components/Alerts';
import { ActionCableProvider } from 'react-actioncable-provider';
import LocalStorage from 'utils/helpers/localStorage';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Role from 'config/roles';
import useEmployeeId from 'utils/helpers/user';
import SideBar from 'components/SideBar/SideBar';
import Header from 'components/Header/Header';
const PasswordReset = React.lazy(() => import('containers/Authentication/PasswordReset'));
const ForgotPassword = React.lazy(() => import('containers/Authentication/ForgotPassword'));
const NewAccount = React.lazy(() => import('containers/Authentication/NewAccount'));
const Login = React.lazy(() => import('containers/Authentication/Login'));
const PageNotFound = React.lazy(() => import('components/PageNotFound/PageNotFound'));
const ErrorAlert = React.lazy(() => import('components/Alerts/ErrorAlert'));

const Loading = () => (
  <div className="d-row min-h-screen justify-center items-center">
    <PropagateLoader color={'#DA2D8B'} loading={true} size={15} />
  </div>
);

const RoutesPath = ({ role = null, currentUser = null }) => {
  let initialRoute = role ? Role[role]?.routes[0] : null;

  return (
    <Suspense fallback={<Loading />}>
      <Switch key={currentUser}>
        <PublicRoute restricted component={Login} path="/login" exact />
        <PublicRoute restricted component={NewAccount} path="/new-account" exact />
        <PublicRoute restricted component={ForgotPassword} path="/forgot-password" exact />
        <PublicRoute restricted component={PasswordReset} path="/reset-password" exact />

        {role &&
          Role[role]?.routes?.map((route, index) => (
            <PrivateRoute
              restricted
              key={index}
              component={route.component}
              path={route.url}
              exact
            />
          ))}

        {currentUser ? (
          <Redirect from="/" to={initialRoute ? initialRoute?.url : '/login'} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              search: window.location.search,
              state: { referrer: window.location.pathname },
            }}
          />
        )}

        <Route component={PageNotFound} path="*" />
      </Switch>
    </Suspense>
  );
};

function Routes() {
  const store = useContext(StoreContext);
  const screenType = Screen.ScreenType();
  const history = createBrowserHistory();
  const userStore = toJS(store.userStore);
  const orderStore = toJS(store.orderStore);
  const { employeeId } = new useEmployeeId();
  const { currentUser, defaultRestaurant, errorMessage, errorAlert, actionToPerform } = userStore;
  const { currentPath, actionCablePayload } = orderStore;

  if (!window.store) window.store = store;

  const afterCloseCallBack = () => {
    if (actionToPerform === 'navigateLogin') {
      history.push('login');
      window.location.reload();
    }
    store.userStore.setErrorAlert(false, '');
  };

  useEffect(() => {
    store.userStore.setDefaultSummaryDate(new Date());

    if (employeeId)
      store.userStore
        .refreshUserAccount(currentUser?.data?.attributes?.email, employeeId)
        .then(response => {
          if (!response) {
            LocalStorage.removeKey('auth_token');
            store.userStore.clearStoredData();
            history.push('/login');
            window?.fcWidget?.destroy();
            window.fcWidget?.user?.create();
          }
        });
    // else history.replace('/');
  }, [employeeId]);

  let access_token = LocalStorage.getAccessToken();
  let url = `wss://${process.env.REACT_APP_WEB_SOCKET_URL}/cable?employee_id=${employeeId}&subscribing_from=restaurant_app&access_token=${access_token}`;

  return (
    <div>
      <Router history={history}>
        <React.Fragment>
          <Suspense fallback={<Loading />}>
            <ActionCableProvider key={employeeId} url={url}>
              <div>
                <ErrorAlert
                  visible={errorAlert}
                  errorMessage={errorMessage}
                  dismissAlert={afterCloseCallBack}
                />

                {actionCablePayload?.notification_for && <ActionCableAlert visible={true} />}

                {screenType.isTabletOrDesktop && currentUser?.data && <Header />}

                {currentUser?.data && (
                  <SideBar
                    key={currentUser?.data}
                    routes={
                      <RoutesPath role={defaultRestaurant?.role} currentUser={currentUser?.data} />
                    }
                  />
                )}

                {!access_token && <RoutesPath key={currentUser?.data} />}
              </div>
            </ActionCableProvider>
          </Suspense>
        </React.Fragment>
      </Router>

      {currentUser?.data?.attributes && currentPath?.help && (
        <FreshChat
          token={process.env.REACT_APP_FRESH_CHAT_TOKEN}
          externalId={currentUser?.data?.attributes?.email}
          firstName={currentUser?.data?.attributes?.first_name}
          lastName={currentUser?.data?.attributes?.last_name}
          email={currentUser?.data?.attributes?.email}
          open={true}
          tags={['restaurants']}
          onInit={widget => {
            widget.user.setProperties({
              restaurant: defaultRestaurant?.label,
              destinationCode: currentPath?.destinationCode,
              bagNumber: currentPath?.bagNumber,
              orderDeadline: currentPath?.orderDeadline,
            });
          }}
        />
      )}

      {currentUser?.data?.attributes &&
        currentPath?.pathName &&
        currentPath?.pathName !== '/order' && (
          <FreshChat
            token={process.env.REACT_APP_FRESH_CHAT_TOKEN}
            firstName={currentUser?.data?.attributes?.first_name}
            lastName={currentUser?.data?.attributes?.last_name}
            email={currentUser?.data?.attributes?.email}
            tags={['restaurants']}
            externalId={currentUser?.data?.attributes?.email}
            onInit={widget => {
              widget.user.setProperties({
                restaurant: defaultRestaurant?.label,
              });
            }}
          />
        )}
    </div>
  );
}

export default observer(Routes);
