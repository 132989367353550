import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { NotFound } from 'components/Shared';
import { observer } from 'mobx-react';
import ReactPaginate from 'react-paginate';
import Screen from 'utils/helpers/ScreenSize';
import { SortButton } from 'components/Shared';
import array from 'utils/helpers/array';
import { Tooltip } from 'components/Shared';
import team from 'utils/helpers/team';

function TeamsTable({ data, handlePageClick, editEmployee, removeEmployee, currentUser }) {
  const history = useHistory();
  const [currentSort, setCurrentSort] = useState('asc');
  const [sortedData, setSortedData] = useState(null);
  const screenType = Screen.ScreenType();
  const pageClick = data => {
    handlePageClick(data.selected + 1);
  };

  useEffect(() => {
    setSortedData(data);
  }, [data?.data, sortedData]);

  const sortByColumnName = column => {
    currentSort === 'asc' ? setCurrentSort('desc') : setCurrentSort('asc');

    //Put a timeout in order to prevent the weird effect of rerendering quickly
    setTimeout(() => {
      setSortedData(array.sortTableColumnWithAttributes(sortedData?.data, column, currentSort));
    }, [500]);
  };

  const repositionList = data => {
    return data?.sort(function (x, y) {
      return x.attributes.email === currentUser?.data?.attributes?.email
        ? -1
        : y.attributes.email === currentUser?.data?.attributes?.email
        ? 1
        : 0;
    });
  };

  return (
    <div className="flex flex-col mt-4">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
            <table className="w-full table-fixed">
              <thead className="bg-white">
                <tr>
                  <th scope="col" className="t-head text-left pl-0.5 md:pl-3 d-row">
                    <small className="t-row">Team Member</small>
                    <SortButton onClick={() => sortByColumnName('first_name')} />
                  </th>
                  {screenType.isTabletOrDesktop && (
                    <th scope="col" className="t-head">
                      <div className="d-row justify-left">
                        <small className="t-row">Email</small>
                      </div>
                    </th>
                  )}

                  {screenType.isTabletOrDesktop && (
                    <th scope="col" className="t-head pl-3">
                      <div className="d-row left">
                        <small className="t-row">Phone Number</small>
                      </div>
                    </th>
                  )}
                  {screenType.isTabletOrDesktop && (
                    <th scope="col" className="t-head">
                      <div className="d-row left">
                        <small className="t-row">Role</small>
                        <Tooltip
                          tooltipText=""
                          custom
                          children={team.roleTooltip()}
                          id="user-role"
                        />
                        <SortButton onClick={() => sortByColumnName('role')} />
                      </div>
                    </th>
                  )}

                  <th scope="col" className="t-head text-center">
                    {''}
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white divide-y divide-gray-200">
                {repositionList(
                  data?.data?.filter(employee => !employee?.attributes?.soft_deleted)
                )?.map(
                  ({
                    id,
                    attributes: { role, email, first_name, last_name, phone, soft_deleted },
                  }) => (
                    <tr key={id} className="odd:bg-table-row hover:bg-gray-100 cursor-pointer">
                      <td className="py-3 align-middle break-words text-left pl-0.5 md:pl-3">
                        <small className="text-xs text-dark-gray font-inter-regular capitalize">
                          {first_name} {last_name}
                        </small>
                      </td>

                      {screenType.isTabletOrDesktop && (
                        <td className="py-3 align-middle break-words text-left">
                          <small className="text-xs text-dark-gray font-inter-regular">
                            {email}
                          </small>
                        </td>
                      )}

                      {screenType.isTabletOrDesktop && (
                        <td className="py-3 align-middle break-words text-left pl-3">
                          <small className="text-xs text-dark-gray font-inter-regular">
                            {phone ? phone : 'Not Provided'}
                          </small>
                        </td>
                      )}

                      {screenType.isTabletOrDesktop && (
                        <td className="py-3 align-middle break-words text-left">
                          <span className="text-xs d-row text-dark-gray capitalize font-inter-regular">
                            {role ? role : 'Not Decided'}
                          </span>
                        </td>
                      )}

                      <td className="py-3 align-middle break-words text-center">
                        {email !== currentUser.data.attributes.email && (
                          <div>
                            <Screen.Desktop>
                              <button
                                className="btn-purple text-xs w-3/6 bg-light-orange break-normal"
                                onClick={() =>
                                  editEmployee({ id, role, email, first_name, last_name, phone })
                                }>
                                Manage
                              </button>
                            </Screen.Desktop>

                            {(screenType.isTablet || screenType.isMobile) && (
                              <button
                                className="btn-mini w-3/6 text-xs bg-light-orange break-normal"
                                onClick={() =>
                                  editEmployee({ id, role, email, first_name, last_name, phone })
                                }>
                                Manage
                              </button>
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>

            {(data?.pagy?.next !== null || data?.pagy?.prev !== null) && (
              <div className="mt-4 text-primary-color">
                <ReactPaginate
                  previousLabel={'Previous'}
                  nextLabel={'Next'}
                  breakLabel={<span className="gap">...</span>}
                  pageCount={data?.pagy?.pages}
                  onPageChange={pageClick}
                  forcePage={data?.pagy?.page - 1 > 0 ? data?.pagy?.page - 1 : 0}
                  containerClassName={'pagination'}
                  previousLinkClassName={'previous_page'}
                  nextLinkClassName={'next_page'}
                  disabledClassName={'disabled'}
                  activeClassName={'active'}
                />
              </div>
            )}
            <NotFound data={data?.data} message="No Employees found" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(TeamsTable);
