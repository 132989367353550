import qs from 'qs';
import api from '../store/api';
import { makeObservable, observable, action, computed, flow, values } from 'mobx';
import { client } from '../store';
import { makePersistable, clearPersistedStore } from 'mobx-persist-store';
import moment from 'moment';
import { errorHandler } from 'utils/middlewares/errorHandler';
import { toJS } from 'mobx';

class ScheduleStore {
  schedules = [];

  constructor() {
    // makePersistable(this, {
    //   name: 'ScheduleStore',
    //   properties: ['defaultDestinationCode', 'destinationCodes'],
    //   storage: window.localStorage,
    // });

    makeObservable(this, {
      schedules: observable,
      getSchedules: action,
    });
  }
  async getSchedules(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.schedules()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.schedules = data?.data?.data;

          return data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error;
        })
      );
  }
  async updateSchedule(menuId, shipmentIds, acceptance, employeeId) {
    return client()
      .put(`${api.schedules()}/${menuId}?employee_id=${employeeId}`, {
        ids: shipmentIds,
        acceptance,
      })
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error;
        })
      );
  }
}

export default ScheduleStore;
