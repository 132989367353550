import React from 'react';
import {
  faFileAlt,
  faCalendarAlt,
  faHome,
  faCalculator,
  faUsers,
  faBell,
  faUtensils,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';

export default {
  owner: {
    routes: [
      {
        label: 'Daily Summary',
        icon: faFileAlt,
        url: '/order-summary',
        tooltip: "Your dashboard for today's orders. View and manage all your orders from here.",
      },
      {
        label: 'Team Management',
        icon: faUsers,
        url: '/team-management',
        tooltip: "View and manage your team's access to the hungerhub restaurant portal",
      },
      {
        label: 'Accounting',
        icon: faCalculator,
        url: '/accounting',
        tooltip: "View and manage your restaurant's banking details and payment history.",
      },
      {
        label: 'Menu',
        icon: faUtensils,
        url: '/menu',
        tooltip: "View your restaurant's menu and request updates.",
      },
      {
        label: 'Schedule',
        icon: faCalendarAlt,
        url: '/schedule',
        tooltip: 'View a schedule of all upcoming orders and confirm your availability.',
      },
      {
        icon: faHome,
        label: 'Restaurant Profile',
        url: '/restaurant-profile',
        tooltip: "Manage your restaurant's address and pick up instructions.",
      },
      {
        icon: faBell,
        label: 'Notifications',
        url: '/notifications',
        tooltip: 'View your notification history.',
      },
      {
        icon: faQuestionCircle,
        label: 'Help',
        url: '/faq',
        tooltip: "View FAQ's and help guides on hungerhub and the restaurant portal.",
      },
    ],
  },
  manager: {
    routes: [
      {
        label: 'Daily Summary',
        icon: faFileAlt,
        url: '/order-summary',
        tooltip: "Your dashboard for today's orders. View and manage all your orders from here.",
      },
      {
        label: 'Team Management',
        icon: faUsers,
        url: '/team-management',
        tooltip: "View and manage your team's access to the hungerhub restaurant portal",
      },
      {
        label: 'Menu',
        icon: faUtensils,
        url: '/menu',
        tooltip: "View your restaurant's menu and request updates.",
      },
      {
        label: 'Schedule',
        icon: faCalendarAlt,
        url: '/schedule',
        tooltip: 'View a schedule of all upcoming orders and confirm your availability.',
      },
      {
        icon: faHome,
        label: 'Restaurant Profile',
        url: '/restaurant-profile',
        tooltip: "Manage your restaurant's address and pick up instructions.",
      },
      {
        icon: faBell,
        label: 'Notifications',
        url: '/notifications',
        tooltip: 'View your notification history.',
      },
      {
        icon: faQuestionCircle,
        label: 'Help',
        url: '/faq',
        tooltip: "View FAQ's and help guides on hungerhub and the restaurant portal.",
      },
    ],
  },
  employee: {
    routes: [
      {
        label: 'Daily Summary',
        icon: faFileAlt,
        url: '/order-summary',
        tooltip: "Your dashboard for today's orders. View and manage all your orders from here.",
      },
      {
        icon: faBell,
        label: 'Notifications',
        url: '/notifications',
        tooltip: 'View your notification history.',
      },
      {
        icon: faQuestionCircle,
        label: 'Help',
        url: '/faq',
        tooltip: "View FAQ's and help guides on hungerhub and the restaurant portal.",
      },
    ],
  },
  accounting: {
    routes: [
      {
        label: 'Accounting',
        icon: faCalculator,
        url: '/accounting',
        tooltip: "View and manage your restaurant's banking details and payment history.",
      },
      {
        icon: faQuestionCircle,
        label: 'Help',
        url: '/faq',
        tooltip: "View FAQ's and help guides on hungerhub and the restaurant portal.",
      },
    ],
  },

  common: {
    routes: [],
  },
};
