import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ModalBackground } from 'components/Shared';
import validation from 'utils/helpers/validation';

function BagSummary(props) {
  const [foodTotal, setFoodTotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);

  useEffect(() => {
    let tax = 0;
    let total = 0;

    const helperArray = props?.data?.data?.filter(
      order => order?.attributes?.status !== 'cancelled'
    );

    helperArray.map(({ attributes: { subtotal } }) => (total = total + Number(subtotal)));
    helperArray.map(({ attributes: { tax_amount } }) => (tax = tax + Number(tax_amount)));

    setFoodTotal(total);
    setTaxTotal(tax);
    setTotalOrders(helperArray?.length);
  }, [props.visible]);

  return (
    <div
      className={
        props.visible
          ? `fixed pin inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center z-20`
          : 'hidden'
      }>
      <ModalBackground />

      <div
        className="rounded-lg overflow-hidden shadow-md transform transition-all sm:max-w-lg sm:w-full overflow-y-auto"
        role="dialog"
        aria-modal="true"
        style={{ width: '500px', maxHeight: '500px' }}
        aria-labelledby="modal-headline">
        <div className="flex bg-white p-6 flex-col">
          <button
            onClick={() => {
              props.setAlert(false);
            }}>
            <FontAwesomeIcon
              icon={faTimes}
              color={'#ADB4C3'}
              size="lg"
              className="absolute top-4 right-4"
            />
          </button>

          <h5 className="font-inter-regular">Order summary by category</h5>

          <table className="w-full table-fixed">
            <thead className="bg-white">
              <tr>
                <th scope="col" className="w-1/6 pt-3 text-left tracking-wider">
                  <small className="text-xs text-black tracking-wider font-inter-regular font-normal">
                    Qty
                  </small>
                </th>

                <th scope="col" className="w-1/2 pt-3 text-left tracking-wider">
                  <small className="text-xs text-black font-inter-regular font-normal">
                    Category
                  </small>
                </th>

                <th scope="col" className="w-1/2 pt-3 text-left tracking-wider">
                  <small className="text-xs text-black font-inter-regular font-normal">Item</small>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {props.data?.items_by_categories?.map(({ quantity, category, item }, index) => (
                <tr key={index} className="cursor-pointer">
                  <td className="py-2 whitespace-nowrap align-top">
                    <small className="text-xs font-inter-regular text-dark-gray">{quantity}</small>
                  </td>

                  <td className="py-2 align-top">
                    <small className="text-xs font-inter-regular text-dark-gray break-normal">
                      {category}
                    </small>
                  </td>

                  <td className="py-2 align-top">
                    <p className="text-sm text-dark-gray font-inter-regular break-normal">{item}</p>
                  </td>
                </tr>
              ))}

              <tr key={2} className="relative cursor-pointer border-t border-gray-200">
                <td className="lg:pr-6 py-3 whitespace-nowrap align-top"></td>

                <td className=" py-3 align-top whitespace-nowrap flex flex-col">
                  <div className="flex flex-col">
                    <small className="input-label pt-2">Total orders:</small>
                    <small className="input-label pt-2">Food Total:</small>
                    <small className="input-label pt-2">Tax:</small>
                    <small className="input-label pt-2">Grand Total:</small>
                  </div>
                </td>

                <td className=" py-3 whitespace-nowrap align-top ">
                  <div className="flex flex-col">
                    <small className="input-label pt-2">{totalOrders}</small>
                    <small className="input-label pt-2">
                      {validation.priceFormatter(foodTotal)}
                    </small>
                    <small className="input-label pt-2">
                      {validation.priceFormatter(taxTotal)}
                    </small>
                    <small className="input-label pt-2">
                      {validation.priceFormatter(taxTotal + foodTotal)}
                    </small>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default BagSummary;
