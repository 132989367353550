const priceFormatter = number => {
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(number);
};

const validatePassword = password => {
  return /^(?=.*\d)(?=.*[a-zA-Z]).{8,100}$/.test(password);
};

const validateEmail = email => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

const isRestaurantUser = user => {
  let restaurantOrganizations = user?.employees?.filter(
    employee => employee?.attributes?.organization_type === 'Restaurant'
  );
  return restaurantOrganizations?.length > 0;
};

export default { priceFormatter, validatePassword, validateEmail, isRestaurantUser };
