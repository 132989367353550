import { useMediaQuery } from 'react-responsive';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 800, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 799 });
  return isMobile ? children : null;
};

function ScreenType() {
  const isTabletOrDesktop = useMediaQuery({ query: '(min-width: 776px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 799px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 800, maxWidth: 991 });

  return { isTabletOrDesktop, isMobile, isTabletOrMobile, isDesktop, isTablet };
}

export default { Desktop, Mobile, Tablet, ScreenType };
