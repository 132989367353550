import { useCallback, useContext } from 'react';
import { StoreContext } from 'index';
import useEmployeeId from 'utils/helpers/user';
import { useQueryMethods } from 'hooks/useQueryMethods';

export const useExportMethods = () => {
  const store = useContext(StoreContext);
  const { defaultRestaurant, employeeId } = new useEmployeeId();

  const exportPDF = useCallback((destinationCode, date, shipmentId) => {
    let params = {
      employee_id: employeeId,
      shipment_id: shipmentId,
      date,
      destination_code: destinationCode,
      restaurantName: defaultRestaurant,
    };
    store.orderStore.exportPDF(params);
  }, []);

  const exportLabel = useCallback((destinationCode, date, shipmentId) => {
    let params = {
      employee_id: employeeId,
      shipment_id: shipmentId,
      date,
      destination_code: destinationCode,
      order_labels_export: true,
      restaurantName: defaultRestaurant,
    };
    store.orderStore.exportLabel(params);
  }, []);

  const exportXLS = useCallback((destinationCode, date, shipmentId) => {
    let params = {
      employee_id: employeeId,
      shipment_id: shipmentId,
      date,
      destination_code: destinationCode,
      restaurantName: defaultRestaurant,
    };
    store.orderStore.exportXLS(params);
  }, []);

  return { exportPDF, exportXLS, exportLabel };
};
