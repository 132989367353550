import {
  HALAL,
  KOSHER,
  GLUTENFREE,
  VEGETARIAN,
  VEGAN,
  DAIRYFREE,
  EXCLUSIVE,
  SPICY,
  NUTFREE,
  CONTAINSNUTS,
  BIODEGRADABLEPACKAGING,
  REUSABLEPACKAGING,
} from 'assets/img';

const menuItemBadge = badge => {
  switch (badge?.replace(/\s+/g, '')) {
    case 'HALAL':
      return HALAL;
    case 'KOSHER':
      return KOSHER;
    case 'GLUTENFREE':
      return GLUTENFREE;
    case 'VEGETARIAN':
      return VEGETARIAN;
    case 'VEGAN':
      return VEGAN;
    case 'DAIRYFREE':
      return DAIRYFREE;
    case 'EXCLUSIVE':
      return EXCLUSIVE;
    case 'SPICY':
      return SPICY;
    case 'NUTFREE':
      return NUTFREE;
    case 'CONTAINSNUTS':
      return CONTAINSNUTS;
    case 'BIODEGRADABLEPACKAGING':
      return BIODEGRADABLEPACKAGING;
    case 'REUSABLEPACKAGING':
      return REUSABLEPACKAGING;
    default:
      return HALAL;
  }
};

export default {
  menuItemBadge,
};
