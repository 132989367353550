import qs from 'qs';
import api from '../store/api';
import { makeObservable, observable, action, computed, flow, values } from 'mobx';
import { client } from '../store';
import { errorHandler } from 'utils/middlewares/errorHandler';
import { toJS } from 'mobx';
import { makePersistable, clearPersistedStore } from 'mobx-persist-store';

class NotificationStore {
  notifications = null;
  notificationsPagy = null;
  unreadNotifications = null;

  constructor() {
    // makePersistable(this, {
    //   name: 'NotificationStore',
    //   properties: ['notifications', 'unreadNotifications'],
    //   storage: window.localStorage,
    // });

    makeObservable(this, {
      notifications: observable,
      notificationsPagy: observable,
      unreadNotifications: observable,
    });
  }
  async getNotifications(params, unread = false) {
    if (!unread) {
      this.notifications = null;
      this.notificationsPagy = null;
    }
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.notifications()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          if (unread) {
            this.unreadNotifications = data?.data?.notifications;
          } else {
            this.notifications = data?.data?.notifications;
            this.notificationsPagy = data?.data?.pagy;
          }
          return data?.data;
        }),
        action('fetchError', error => {
          // errorHandler(error.response);
          return error;
        })
      );
  }

  async clearNotifications(id, payload, params) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.notifications()}/${id}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data?.data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error;
        })
      );
  }
}

export default NotificationStore;
