import React, { useEffect, useReducer, useContext, useRef } from 'react';
import { StoreContext } from 'index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { toJS } from 'mobx';
import { ModalBackground } from 'components/Shared';
import Input, { isValidPhoneNumber } from 'react-phone-number-input/input';
import { errorHandler } from 'utils/middlewares/errorHandler';
import Validation from 'utils/helpers/validation';
import { Tooltip } from 'components/Shared';
import team from 'utils/helpers/team';
import object from 'utils/helpers/object';

const roles = [
  { label: 'Owner', value: 'owner' },
  { label: 'Manager', value: 'manager' },
  { label: 'Employee', value: 'employee' },
  { label: 'Accounting', value: 'accounting' },
];

function EmployeeDetails({ visible, closeModal, employeeDetails, removeEmployee }) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const { defaultRestaurant } = userStore;
  const inputEl = useRef(null);
  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    role: '',
    invalidEmail: false,
    disableFields: false,
    invalidRole: false,
    isSoftDeleted: false,
  };
  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return action.payload;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }
  const [provider, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (employeeDetails?.id) fillUserFields(employeeDetails);
    else dispatch({ type: 'reset', payload: initialState });
  }, [employeeDetails]);

  const fillUserFields = employeeInfo => {
    dispatch({ field: 'phone', value: employeeInfo.phone });
    dispatch({ field: 'email', value: employeeInfo.email });
    dispatch({ field: 'firstName', value: employeeInfo.first_name });
    dispatch({ field: 'lastName', value: employeeInfo.last_name });
    if (employeeInfo.role)
      dispatch({
        field: 'role',
        value: { label: employeeInfo.role, value: employeeInfo.role },
      });

    if (!employeeDetails?.id) {
      let employees = employeeInfo?.employees;
      let obj = employees.find(
        employee =>
          employee.attributes?.organization_type === 'Restaurant' &&
          Number(employee.attributes?.organization_id) ===
            Number(defaultRestaurant?.restaurantId) &&
          employee.attributes?.soft_deleted
      );

      if (object.isPresent(obj)) {
        dispatch({ field: 'isSoftDeleted', value: true });
      }
    }
  };

  const submit = e => {
    e.preventDefault();
    let payload = {
      email: provider.email,
      first_name: provider.firstName,
      last_name: provider.lastName,
      role: provider.role?.value,
      phone: provider.phone,
    };

    if (!employeeDetails?.id && provider.isSoftDeleted) {
      payload.restore = true;
    }

    if (!provider.role) dispatch({ field: 'invalidRole', value: true });
    else
      !provider.phone || isValidPhoneNumber(provider.phone)
        ? employeeDetails?.id
          ? updateEmployee(payload)
          : addNewEmployee(payload)
        : errorHandler({
            title: 'You have entered an invalid phone number.',
            body: 'At this time only numbers with country code +1 are accepted. And in the following format: (123) 234-5678',
          });
  };

  const updateEmployee = payload => {
    store.userStore
      .updateEmployee(
        employeeDetails?.id,
        { employee_id: userStore?.defaultRestaurant?.value },
        payload
      )
      .then(response => {
        if (response?.data?.employee?.id) closeModal();
      });
  };

  const addNewEmployee = payload => {
    store.userStore
      .addEmployee({ employee_id: userStore?.defaultRestaurant?.value }, payload)
      .then(response => {
        if (response?.data?.employee?.id) closeModal();
      });
  };

  const onChange = selected => {
    dispatch({ field: 'invalidRole', value: false });
    dispatch({ field: 'role', value: selected });
  };

  const sendResetPasswordInstructions = () => {
    closeModal();
    store.userStore.setLoader(true);
    store.userStore
      .forgotPassword({ email: employeeDetails.email, organization: 'Restaurant' })
      .then(response => {
        store.userStore.setLoader(false);
        if (response?.data?.message) {
          errorHandler({
            title: 'Reset password instructions.',
            body: `A password reset email has been set to ${employeeDetails.first_name} ${employeeDetails.last_name}`,
          });
        }
      });
  };

  const checkEmailValid = email => {
    if (Validation.validateEmail(email)) {
      dispatch({ field: 'invalidEmail', value: false });
      store.userStore.setLoader(true);
      store.userStore.ifUserExist(email).then(response => {
        store.userStore.setLoader(false);
        if (response?.status === 200) {
          dispatch({ field: 'disableFields', value: true });
          fillUserFields(response?.data?.data?.attributes);
        } else dispatch({ field: 'disableFields', value: false });
      });
    } else dispatch({ field: 'invalidEmail', value: true });
  };

  return (
    <div
      className={
        visible
          ? `fixed pin inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center z-20`
          : 'hidden'
      }>
      <ModalBackground />

      <div
        className="rounded-lg bg-white overflow-y-auto md:overflow-visible shadow-md transform transition-all sm:max-w-lg sm:w-full animate-fade-in-down"
        role="dialog"
        aria-modal="true"
        style={{ width: '1290px' }}
        aria-labelledby="modal-headline">
        <div className="flex p-6 flex-col">
          <button
            onClick={() => {
              closeModal();
            }}>
            <FontAwesomeIcon
              icon={faTimes}
              color={'#ADB4C3'}
              size="lg"
              className="absolute top-4 right-4"
            />
          </button>
          <form className="rounded pt-4 w-full" onSubmit={e => submit(e)}>
            <div className="d-row flex-wrap">
              <div className="w-full md:w-1/2 md:pr-1">
                <small className="input-label">First Name*</small>
                <input
                  className="input-light"
                  name="firstName"
                  id="firstName"
                  type="text"
                  value={provider.firstName}
                  disabled={provider.disableFields}
                  onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                  required
                />
              </div>
              <div className="w-full md:w-1/2">
                <small className="input-label">Last Name*</small>
                <input
                  className="input-light"
                  name="lastName"
                  id="lastName"
                  value={provider.lastName}
                  disabled={provider.disableFields}
                  type="text"
                  onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                  required
                />
              </div>
            </div>
            <div className="d-row flex-wrap">
              <div className="w-full md:w-1/2 md:pr-1">
                <div className="d-row justify-between mt-2">
                  <small className="input-label">Email*</small>
                  <small
                    className={`input-label text-red-500 ${
                      provider.invalidEmail ? 'visible' : 'invisible'
                    }`}>
                    Invalid Email*
                  </small>
                </div>
                <input
                  className="input-light"
                  name="email"
                  id="email"
                  ref={inputEl}
                  value={provider.email}
                  onBlur={e => checkEmailValid(e.target.value)}
                  type="email"
                  onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                  required
                />
              </div>
              <div className="w-full md:w-1/2">
                <small className="input-label">Phone Number</small>
                <Input
                  defaultCountry="CA"
                  value={provider.phone}
                  disabled={provider.disableFields}
                  className="input-light"
                  onChange={value => dispatch({ field: 'phone', value: value })}
                />
              </div>
            </div>

            <div className="d-col">
              <div className="d-row py-1">
                <small className="input-label">Role*</small>
                <Tooltip tooltipText="" custom children={team.roleTooltip()} id="user-role" />
              </div>
              <Select
                className="font-sf-regular capitalize text-xs focus:outline-none ring-transparent shadow-none"
                options={roles}
                value={provider.role}
                onChange={onChange}
              />
              <small
                className={`input-label text-red-500 ${
                  provider.invalidRole ? 'visible' : 'invisible'
                }`}>
                Select Role*
              </small>
            </div>

            <div className="items-center flex flex-col pt-2 md:pt-24">
              {employeeDetails?.id && (
                <div className="w-full">
                  <button
                    type="button"
                    className="btn-purple text-sm w-full bg-light-red break-normal"
                    onClick={() => {
                      removeEmployee({
                        id: employeeDetails.id,
                        fullName: employeeDetails.first_name + ' ' + employeeDetails.last_name,
                      });
                    }}>
                    Delete {employeeDetails?.first_name}
                  </button>

                  <button
                    onClick={sendResetPasswordInstructions}
                    className="btn-purple text-sm w-full my-2 bg-pink-purple break-normal"
                    type="button">
                    Reset Password
                  </button>
                </div>
              )}

              <button className="btn-purple w-full text-sm" type="submit">
                {employeeDetails?.id
                  ? 'Update Details'
                  : provider.isSoftDeleted
                  ? 'Revert Team Member'
                  : ' Add Team Member'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EmployeeDetails;
