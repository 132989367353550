import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUtensils, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { SpecialInstruction } from 'assets/img';
import Validation from 'utils/helpers/validation';
import Screen from 'utils/helpers/ScreenSize';
import { ModalBackground } from 'components/Shared';
import { useFreshChatMethods } from 'hooks/useFreshChatMethods';
import dateTime from 'utils/helpers/dateTime';
import useEmployeeId from 'utils/helpers/user';

function BagDetail({ orderData, closeModal, currentPath, destinationCode, cutoff }) {
  const screenType = Screen.ScreenType();
  const { openBagChat } = useFreshChatMethods();
  const { timeZone } = new useEmployeeId();

  return (
    <div
      className={
        orderData
          ? `fixed pin inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center z-20`
          : 'hidden'
      }>
      <ModalBackground />

      <div
        className="rounded-lg overflow-hidden shadow-md transform transition-all sm:max-w-lg sm:w-full animate-fade-in-down"
        role="dialog"
        aria-modal="true"
        style={{ width: '1290px' }}
        aria-labelledby="modal-headline">
        <div className="flex bg-white p-6 flex-col">
          <button onClick={closeModal}>
            <FontAwesomeIcon
              icon={faTimes}
              color={'#ADB4C3'}
              size="lg"
              className="absolute top-4 right-4"
            />
          </button>

          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col">
              {orderData?.status === 'cancelled' ? (
                <small className="text-red-500 font-inter-regular">Bag removed</small>
              ) : (
                <h5 className="font-inter-regular">Bag {orderData?.bag_number}</h5>
              )}
              {screenType.isDesktop ? (
                <small className="text-sm font-inter-regular">{orderData?.user_name}</small>
              ) : (
                <small className="text-xs font-inter-regular">
                  {orderData?.user_name} - {destinationCode}
                </small>
              )}
            </div>
            <div className="flex flex-col">
              <small className="font-inter-regular">Ordered at: </small>
              <span className="font-inter-regular text-sm">
                {dateTime.formatDate(orderData?.ordered_at, 'YYYY-MM-DD', timeZone)},{'  '}
                <small className="text-red-500 text-sm">
                  *{dateTime.formatTime(orderData?.ordered_at, timeZone)}
                </small>
              </span>
            </div>
          </div>

          <table className="w-full table-fixed">
            <thead className="bg-white">
              <tr>
                <th scope="col" className="lg:pr-6 pt-3 w-1/6 text-left tracking-wider">
                  <small className="text-xs text-black tracking-wider font-inter-regular font-normal">
                    Qty
                  </small>
                </th>

                <th scope="col" className="px-1.5 lg:px-6 pt-3 text-left tracking-wider">
                  <small className="text-xs text-black font-inter-regular font-normal">Item</small>
                </th>

                <th scope="col" className="px-1.5 w-1/6 pt-3 text-left tracking-wider">
                  <small className="text-xs text-black font-inter-regular font-normal">Price</small>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {orderData?.order_items.map(({ id, attributes }) => (
                <tr key={id} className="relative cursor-pointer">
                  <td className="lg:pr-6 py-3 whitespace-nowrap align-top">
                    <small className="text-xs font-inter-regular text-dark-gray">
                      {attributes?.quantity}
                    </small>
                  </td>

                  <td className="px-1.5 lg:px-6 py-3 align-top" colSpan="2">
                    <table className="w-full table-fixed">
                      <tbody>
                        <tr>
                          <td colSpan={screenType.isMobile ? '5' : '7'}>
                            <span className="text-sm text-black font-inter-regular flex flex-row items-start">
                              {attributes?.special_instructions && (
                                <img
                                  src={SpecialInstruction}
                                  className="object-contain h-7 w-7 pr-2"
                                  alt=""
                                />
                              )}
                              <p className="break-normal font-inter-regular text-xs text-black">
                                {attributes?.menu_item?.title} ({attributes.category}){' '}
                              </p>
                            </span>

                            <p className="break-normal text-xs text-pink-500 font-inter-regular">
                              {attributes?.special_instructions}
                            </p>
                          </td>
                          <td className="flex items-start">
                            <small className="text-xs md:text-xs text-black font-inter-regular">
                              {Validation.priceFormatter(attributes?.menu_item?.price)}
                            </small>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="w-full table-fixed">
                      <tbody>
                        {attributes?.order_item_options?.map(({ attributes }) => (
                          <tr>
                            <td colSpan={screenType.isMobile ? '5' : '7'}>
                              {' '}
                              <small className="mt-0 font-inter-regular text-dark-gray break-normal text-xs">
                                {attributes?.option_category_name}: {attributes?.option_name}
                              </small>
                            </td>

                            <td>
                              <small className="font-inter-regular text-xs flex text-dark-gray flex-col">
                                {Validation.priceFormatter(attributes?.price)}
                              </small>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              ))}

              <tr key={2} className="relative cursor-pointer border-t border-gray-200">
                <td className="lg:pr-6 py-3 whitespace-nowrap align-top"></td>

                <td className="px-1.5 lg:px-6 py-3 align-top whitespace-nowrap flex flex-col">
                  <div className="flex flex-col">
                    <small className="font-inter-regular text-sm pt-2">Tax :</small>
                    <small className="font-inter-regular text-sm pt-2">Total :</small>
                  </div>
                </td>

                <td className="px-1.5 py-3 whitespace-nowrap align-top ">
                  <div className="flex flex-col">
                    <small className="font-inter-regular pt-2">
                      {Validation.priceFormatter(orderData?.hst)}
                    </small>
                    <small className="font-inter-regular pt-2">
                      {Validation.priceFormatter(orderData?.grand_total)}
                    </small>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          {orderData?.permanent_instructions && (
            <div className="bg-light-gray py-2 mt-3 px-3 w-full rounded-lg">
              <FontAwesomeIcon icon={faExclamationTriangle} color={'#FF9966'} size="sm" />
              <small className="text-dark-gray text-xs font-inter-regular pl-3">
                {orderData?.permanent_instructions}
              </small>
            </div>
          )}

          <div className="justify-between flex flex-row my-4 items-center">
            <button
              onClick={() =>
                openBagChat(
                  orderData?.bag_number,
                  destinationCode,
                  dateTime.formatTime(cutoff, timeZone)
                )
              }
              disabled={currentPath?.help}
              className={`btn-purple px-10 ${currentPath?.help && 'opacity-50'}`}>
              <small>Help</small>
            </button>
            {orderData?.cutlery && (
              <div>
                <FontAwesomeIcon icon={faUtensils} color={'#6D69D3'} size="sm" />
                <small className="text-dark-gray font-inter-regular pl-3">Cutlery required</small>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BagDetail;
