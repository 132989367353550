import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { HeedBadge } from 'components/HeedBadge';
import { Link } from 'react-router-dom';
import { NotFound } from 'components/Shared';
import Validation from 'utils/helpers/validation';
import { observer } from 'mobx-react';
import { StoreContext } from 'index';
import { toJS } from 'mobx';
import dateTime from 'utils/helpers/dateTime';
import { ETA } from 'components/Shared';
import { useQueryMethods } from 'hooks/useQueryMethods';
import useEmployeeId from 'utils/helpers/user';
import object from 'utils/helpers/object';

function CateringSummaryMobile({ data, selectedDate }) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const { defaultRestaurant } = userStore;
  const orderStore = toJS(store.orderStore);
  const { timeZone } = new useEmployeeId();
  const { shipmentsETA } = orderStore;
  const { cateringShipmentRoute } = useQueryMethods();

  const linkToPath = summary => {
    return cateringShipmentRoute(summary);
  };

  const findETA = shipmentId => {
    return !object.isEmpty(shipmentsETA) ? shipmentsETA[shipmentId] : null;
  };

  return (
    <div className="flex flex-col mt-4">
      {data?.data?.map((summary, index) => (
        <Link to={() => linkToPath(summary)}>
          <div
            className={`${
              index % 2 === 0 ? 'bg-table-row' : 'bg-white'
            } rounded-md w-full shadow-lg p-3 self-center d-col justify-between mt-4 border border-gray-200`}>
            <div className="d-col text-left">
              <strong className="text-xs font-inter-medium">Menu</strong>
              <small className="text-xs text-dim-black font-inter-regular">
                {summary?.menu_name}
              </small>
            </div>

            <div className="d-row justify-between">
              <div className="d-col">
                <strong className="text-xs font-inter-medium">Dest.</strong>

                <div className="flex items-center justify-left">
                  <FontAwesomeIcon icon={faExternalLinkAlt} color={'#393838'} size="xs" />

                  <small className="text-sm font-inter-regular pl-2 text-dim-black">
                    {summary.destination_code}
                  </small>
                </div>
              </div>

              <div className="d-col text-right">
                <strong className="text-xs font-inter-medium">Items</strong>

                <small className="text-sm text-dim-black font-inter-regular">
                  {summary?.items}
                </small>
              </div>
            </div>

            <div className="d-row justify-between pt-2">
              <div className="d-col">
                <strong className="text-xs font-inter-medium">Pickup</strong>

                <small className="text-sm text-dim-black font-inter-regular">
                  {summary.pickup ? dateTime.formatTime(summary.pickup, timeZone) : 'Not Available'}
                </small>
              </div>
            </div>

            <div className="d-row justify-between pt-2">
              <div className="d-col">
                <strong className="text-xs font-inter-medium text-left">Total</strong>
                <small className="text-sm font-inter-regular text-dim-black">
                  {Validation.priceFormatter(summary.food_total)}
                </small>
              </div>

              <div className="d-col">
                <div>
                  {summary?.status === 'confirmed' &&
                  (findETA(summary.shipment_id)?.pickup_started ||
                    findETA(summary.shipment_id)?.delivered ||
                    findETA(summary.shipment_id)?.picked_up) ? (
                    <div className="animate-fade-in-down d-row">
                      <ETA eta={findETA(summary.shipment_id)} />
                    </div>
                  ) : (
                    <HeedBadge text={summary.status} className="capitalize" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Link>
      ))}

      <NotFound data={data?.data} message="No Orders Today" />
    </div>
  );
}

export default observer(CateringSummaryMobile);
