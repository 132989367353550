import React from 'react';
import Screen from 'utils/helpers/ScreenSize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Counter = ({ label, count, icon, fontAwesomeIcon }) => {
  return (
    <div>
      <Screen.Desktop>
        <div className="border-2 rounded-lg text-left items-start w-36 px-3 mx-2 py-2 shadow-md">
          <label className="text-xs text-gray-500 font-normal font-inter-medium">{label}</label>

          <div className="flex flex-row pt-2 pb-2 text-center items-center">
            {fontAwesomeIcon ? (
              <FontAwesomeIcon icon={icon} color={'#6D69D3'} size="lg" className="mr-2" />
            ) : (
              <img src={icon} className="object-contain h-7 w-7 pr-2" />
            )}
            <small className="font-normal font-inter-semibold text-2xl">{count}</small>
          </div>
        </div>
      </Screen.Desktop>

      <Screen.Tablet>
        <div className="border-2 rounded-lg text-left items-start w-28 px-2 mx-2 py-1 shadow-md">
          <label className="text-xs text-gray-500 font-normal font-inter-medium">{label}</label>

          <div className="flex flex-row pt-1 pb-2 text-center items-center">
            {fontAwesomeIcon ? (
              <FontAwesomeIcon icon={icon} color={'#6D69D3'} size="lg" className="mr-2" />
            ) : (
              <img src={icon} className="object-contain h-6 w-6 pr-2" />
            )}
            <label className="text-xl font-normal font-inter-semibold">{count}</label>
          </div>
        </div>
      </Screen.Tablet>

      <Screen.Mobile>
        <div className="d-row mb-2">
          {fontAwesomeIcon ? (
            <FontAwesomeIcon icon={icon} color={'#6D69D3'} size="sm" className="mr-2" />
          ) : (
            <img src={icon} className="object-contain h-6 w-6 pr-2" />
          )}
          <label className="text-xxs font-normal font-inter-medium">
            {count} {label}
          </label>
        </div>
      </Screen.Mobile>
    </div>
  );
};

export default Counter;
