import React, { useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import Select from 'react-select';
import { StoreContext } from 'index';
import { useHistory } from 'react-router-dom';
import dateTime from 'utils/helpers/dateTime';
import { useQueryMethods } from 'hooks/useQueryMethods';
import useEmployeeId from 'utils/helpers/user';

function DestinationCodeSelect({ destinationCode, cutoff }) {
  const store = useContext(StoreContext);
  const orderStore = toJS(store.orderStore);
  const { destinationCodes, defaultDestinationCode } = orderStore;
  let history = useHistory();
  const { employeeId, timeZone } = new useEmployeeId();
  const { getParam } = useQueryMethods();

  useEffect(() => {
    let sourceOfBusiness = getParam('source_of_business');

    if (sourceOfBusiness)
      store.orderStore.setDefaultDestinationCode({
        label: `${destinationCode} - ${
          sourceOfBusiness === 'hungerhub' ? 'hungerhub Catering' : 'Direct Catering'
        }`,
      });
    else
      store.orderStore.setDefaultDestinationCode({
        label: `${dateTime.formatTime(cutoff, timeZone)} - ${destinationCode}`,
      });
  }, []);

  const onChange = selected => {
    const params = new URLSearchParams();

    if (
      selected.value.source_of_business != null &&
      (selected.value.source_of_business == 'hungerhub' ||
        selected.value.source_of_business == 'restaurant')
    ) {
      params.append('source_of_business', selected.value.source_of_business);
      history.push({
        pathname: `/catering-order/${selected.shipment_id}`,
        search: params.toString(),
      });
      store.userStore.setLoader(true);
      store.orderStore
        .getOrdersPrepare({ employee_id: employeeId }, selected?.shipment_id)
        .then(response => {
          store.userStore.setLoader(false);
        });
    } else {
      history.push({
        pathname: `/uncatering-order/${selected.shipment_id}`,
        search: params.toString(),
      });

      store.userStore.setLoader(true);
      store.orderStore
        .getOrdersPrepare({ employee_id: employeeId }, selected.shipment_id)
        .then(response => {
          store.userStore.setLoader(false);
        });
    }

    store.orderStore.setDefaultDestinationCode(selected);
  };

  const getSortedCodes = () => {
    return destinationCodes.sort((x, y) => {
      return x.label === defaultDestinationCode.label
        ? -1
        : y.label === defaultDestinationCode.label
        ? 1
        : 0;
    });
  };

  return (
    <Select
      className="font-sf-regular text-xs focus:outline-none ring-transparent shadow-none cursor-pointer"
      options={getSortedCodes()}
      value={defaultDestinationCode}
      // selected={defaultDestinationCode}

      onChange={onChange}
      key={defaultDestinationCode}
    />
  );
}

export default observer(DestinationCodeSelect);
