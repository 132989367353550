import React from 'react';
import { css } from '@emotion/react';
import PropagateLoader from 'react-spinners/PropagateLoader';

const override = css`
  display: block;
  opacity: 1;
`;

function Loader() {
  return (
    <div className="h-screen w-screen flex items-center justify-center absolute z-40">
      <PropagateLoader color={'#DA2D8B'} loading={true} css={override} size={15} />
    </div>
  );
}

export default Loader;
