import { API_URL } from '../utils/constants/api';
import LocalStorage from 'utils/helpers/localStorage';
import axios from 'axios';

const makeHeaders = () => {
  let access_token = LocalStorage.getAccessToken();
  if (access_token !== null)
    return {
      'Content-Type': 'application/json',
      'ACCESS-TOKEN': access_token,
    };
  else
    return {
      'Content-Type': 'application/json',
    };
};

const client = () => {
  return axios.create({
    baseURL: API_URL,
    headers: makeHeaders(),
  });
};

export { client };
