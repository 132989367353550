const removeExtraKeys = obj => {
  return Object.entries(obj).reduce(
    (a, [k, v]) => (v !== null && v !== undefined && v !== '' ? ((a[k] = v), a) : a),
    {}
  );
};

const isEmpty = obj => {
  return Object.keys(obj ? obj : {}).length === 0;
};

const isPresent = obj => {
  return Object.keys(obj ? obj : {}).length > 0;
};

const hasKey = (obj, key) => {
  return obj?.hasOwnProperty(key);
};

export default {
  removeExtraKeys,
  isEmpty,
  hasKey,
  isPresent,
};
