import qs from 'qs';
import api from '../store/api';
import { makeObservable, observable, action, computed, flow, values } from 'mobx';
import { client } from '../store';
import { makePersistable, clearPersistedStore } from 'mobx-persist-store';
import moment from 'moment';
import { toJS } from 'mobx';

class MenuStore {
  menus = [];
  optionCategories = [];
  menuTypes = [];
  defaultMenuType = null;
  menuCategories = [];

  constructor() {
    // makePersistable(this, {
    //   name: 'MenuStore',
    //   properties: ['defaultDestinationCode', 'destinationCodes'],
    //   storage: window.localStorage,
    // });

    makeObservable(this, {
      menus: observable,
      optionCategories: observable,
      menuTypes: observable,
      menuCategories: observable,
      getOptionCategories: action,
      getMenus: action,
      clearOptionCategories: action,
      defaultMenuType: observable,
      updateDefaultMenu: action,
    });
  }

  async getMenus(restaurant_id) {
    return client()
      .get(api.menus(restaurant_id))
      .then(
        action('fetchSuccess', data => {
          this.menuTypes = data?.data?.data?.map(menu => ({
            label: menu?.attributes?.name,
            value: menu.id,
            menu: menu?.attributes,
          }));

          this.defaultMenuType = this.menuTypes[0];
          this.menus = data?.data?.data;
          this.pluckAndSetCategories();
          return data;
        }),
        action('fetchError', error => {
          // errorHandler(error.response);
          return error;
        })
      );
  }

  updateDefaultMenu(selected) {
    this.defaultMenuType = selected;
    this.menuCategories = [];
    this.pluckAndSetCategories();
  }

  pluckAndSetCategories() {
    let filteredMenu = toJS(this.menus)?.filter(
      menu => menu.id === toJS(this.defaultMenuType)?.value
    );
    let _categories = filteredMenu[0]?.attributes?.menu_categories.map(({ attributes }) => ({
      id: attributes.id,
      name: attributes.name,
    }));

    this.menuCategories = _categories;
  }

  async getOptionCategories(menuCategoryId, restaurant_id) {
    return client()
      .get(`${api.optionCategories(menuCategoryId, restaurant_id)}`)
      .then(
        action('fetchSuccess', data => {
          this.optionCategories = data;
          return data;
        }),
        action('fetchError', error => {
          // errorHandler(error.response);
          return error;
        })
      );
  }

  clearOptionCategories() {
    this.optionCategories = [];
  }
}

export default MenuStore;
