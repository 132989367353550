import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlink } from '@fortawesome/free-solid-svg-icons';
const NotFound = props => {
  return (
    <div className="w-full flex justify-center content-center items-center">
      {!props.data?.length > 0 ? (
        <div className="p-5">
          <FontAwesomeIcon icon={faUnlink} color={'#DA2D8B'} size="lg" />
          <span className="font-inter-regular pl-3">{props.message}</span>
        </div>
      ) : null}
    </div>
  );
};

export default NotFound;
