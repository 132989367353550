import qs from 'qs';
import api from '../store/api';
import { makeObservable, observable, action } from 'mobx';
import { client } from '../store';
import LocalStorage from 'utils/helpers/localStorage';
import { makePersistable, clearPersistedStore } from 'mobx-persist-store';
import { errorHandler } from 'utils/middlewares/errorHandler';
import { toJS } from 'mobx';
import validation from 'utils/helpers/validation';
import { useHistory } from 'react-router-dom';

class UserStore {
  currentUser = {};
  isLoading = false;
  defaultRestaurant = {};
  defaultDateSelect = new Date();
  employees = {};
  bankingInformation = null;
  accountingEmails = null;
  actionToPerform = '';
  restaurantProfile = null;
  //To display a central error popups
  errorMessage = '';
  errorAlert = false;
  reportHistory = [];
  fortnightlyStatus = false;

  restaurantUser = null; //For the user profile section

  constructor() {
    makePersistable(this, {
      name: 'UserStore',
      properties: ['currentUser', 'defaultRestaurant', 'defaultDateSelect'],
      storage: window.localStorage,
    });
    makeObservable(this, {
      isLoading: observable,
      bankingInformation: observable,
      currentUser: observable,
      defaultRestaurant: observable,
      employees: observable,
      errorMessage: observable,
      defaultDateSelect: observable,
      errorAlert: observable,
      setLoader: action,
      signIn: action,
      clearStoredData: action,
      getBankingInformation: action,
      accountingEmails: observable,
      getAccountingEmails: action,
      actionToPerform: observable,
      restaurantProfile: observable,
      restaurantUser: observable,
      reportHistory: observable,
      fortnightlyStatus: observable,
    });
  }

  logout() {
    this.currentUser = {};
  }

  async updatePassword(payload) {}

  async signIn(payload) {
    return client()
      .post(api.login(), payload)
      .then(
        action('fetchSuccess', data => {
          if (validation.isRestaurantUser(data?.data?.data?.attributes)) {
            this.currentUser = data?.data;
            let restaurantOrganizations = data?.data?.data?.attributes?.employees?.filter(
              employee =>
                employee?.attributes?.organization_type === 'Restaurant' &&
                !employee?.attributes?.soft_deleted
            );
            let restaurant = restaurantOrganizations[0];
            this.setDefaultRestaurant({
              label: restaurant?.attributes?.organization_name,
              value: restaurant?.id, //id = employee id
              street: restaurant?.attributes?.organization_name,
              restaurantId: restaurant?.attributes?.organization_id,
              role: restaurant?.attributes?.role,
              timeZone: restaurant?.attributes?.tzinfo,
            });
            LocalStorage.setAccessToken(data?.data?.data?.attributes?.access_token);
            return data.data;
          } else return 'Invalid user';
        }),
        action('fetchError', error => {
          return error.response;
        })
      );
  }

  setDefaultRestaurant(restaurant) {
    this.defaultRestaurant = restaurant;
  }

  setDefaultSummaryDate(date) {
    this.defaultDateSelect = date;
  }

  setErrorAlert(visible, errorMessage, action = '') {
    this.errorMessage = errorMessage;
    this.errorAlert = visible;
    this.actionToPerform = action;
  }

  async clearStoredData() {
    this.currentUser = {};
    this.defaultRestaurant = {};
    this.defaultDateSelect = new Date();
    await clearPersistedStore(this);
  }

  async setLoader(value) {
    this.isLoading = value;
  }

  async getEmployees(params, perPage = 8, pageNumber = 1) {
    let _params = params;
    _params.per_page = perPage;
    _params.page = pageNumber;

    let paramsString = qs.stringify(_params);
    return client()
      .get(`${api.employees()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.employees = data?.data;
          return data;
        }),
        action('fetchError', error => {
          // errorHandler(error.response);
          return error;
        })
      );
  }

  async addEmployee(params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.employees()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          this.getEmployees(params);
          return data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error;
        })
      );
  }

  async updateEmployee(id, params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .patch(`${api.employees()}/${id}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          this.getEmployees(params);
          return data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error;
        })
      );
  }

  async deleteEmployee(id, fullName, params) {
    let paramsString = qs.stringify(params);
    return client()
      .delete(`${api.employees()}/${id}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.setErrorAlert(true, {
            title: 'Successfully deleted!',
            body: `${fullName} has been removed from your account. If you would like to re-enable access please recreate the user.`,
          });
          this.getEmployees(params);
          return data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error;
        })
      );
  }

  async forgotPassword(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.forgotPassword()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          errorHandler({
            title: 'No email found',
            body: 'No user associated with this account. If you need assistance please start a live chat or email us at help@hungerhub.com.',
          });
          return error;
        })
      );
  }

  async getBankingInformation() {
    let currentUser = toJS(this.currentUser);
    let defaultRestaurant = toJS(this.defaultRestaurant);

    return client()
      .get(api.bankingInformation(currentUser?.data?.id, defaultRestaurant?.value))
      .then(
        action('fetchSuccess', data => {
          this.bankingInformation = data?.data?.data;
          return data;
        }),
        action('fetchError', error => {
          errorHandler({
            title: 'No Information Found',
            body: 'No user associated with this account. If you need assistance please start a live chat or email us at help@hungerhub.com.',
          });
          return error;
        })
      );
  }

  async updateBankingInformation(payload) {
    let currentUser = toJS(this.currentUser);
    let defaultRestaurant = toJS(this.defaultRestaurant);

    return client()
      .put(api.bankingInformation(currentUser?.data?.id, defaultRestaurant?.value), payload)
      .then(
        action('fetchSuccess', data => {
          this.bankingInformation = data?.data?.data;
          return data;
        }),
        action('fetchError', error => {
          errorHandler({
            title: 'No Information Found',
            body: 'No user associated with this account. If you need assistance please start a live chat or email us at help@hungerhub.com.',
          });
          return error;
        })
      );
  }

  async resetPassword(payload) {
    return client()
      .post(api.resetPassword(), payload)
      .then(
        action('fetchSuccess', data => {
          errorHandler(
            {
              title: 'Password Reset Successful',
              body: 'Your password has been reset successfully.',
            },
            'navigateLogin'
          );
          return data?.data;
        }),
        action('fetchError', error => {
          errorHandler(
            {
              title: 'Unable to update',
              body: 'Link is invalid or expired. Try generating a new link. Please login now to access your account.',
            },
            'navigateLogin'
          );
          return error;
        })
      );
  }

  async getAccountingEmails() {
    let currentUser = toJS(this.currentUser);
    let defaultRestaurant = toJS(this.defaultRestaurant);

    return client()
      .get(api.accountingEmails(currentUser?.data?.id, defaultRestaurant?.value))
      .then(
        action('fetchSuccess', data => {
          this.accountingEmails = data?.data?.data;
          return data;
        }),
        action('fetchError', error => {
          errorHandler({
            title: 'No Information Found',
            body: 'No user associated with this account. If you need assistance please start a live chat or email us at help@hungerhub.com.',
          });
          return error;
        })
      );
  }

  async updateAccountingEmails(emails) {
    let currentUser = toJS(this.currentUser);
    let defaultRestaurant = toJS(this.defaultRestaurant);

    return client()
      .put(api.accountingEmails(currentUser?.data?.id, defaultRestaurant?.value), {
        accounting_emails: emails,
      })
      .then(
        action('fetchSuccess', data => {
          this.accountingEmails = data?.data?.data;
          errorHandler({
            title: 'Accounting emails updated',
            body: 'Accounting emails have been updated successfully.',
          });
          return data;
        }),
        action('fetchError', error => {
          errorHandler({
            title: 'No Information Found',
            body: 'No user associated with this account. If you need assistance please start a live chat or email us at help@hungerhub.com.',
          });
          return error;
        })
      );
  }

  async exportSalesReport(startDate, endDate, email) {
    let defaultRestaurant = toJS(this.defaultRestaurant);
    const payload = {
      email,
      employee_id: defaultRestaurant?.value,
      start_date: startDate,
      end_date: endDate,
    };

    return client()
      .post(api.salesReport(), payload)
      .then(
        action('fetchSuccess', data => {
          errorHandler({
            title: 'Report generated',
            body: `Usage report generated from ${startDate} to ${endDate} and emailed.`,
          });
          return data;
        }),
        action('fetchError', error => {
          errorHandler({
            title: 'Unable to generate sales report',
            body: 'If you need assistance please start a live chat or email us at help@hungerhub.com.',
          });
          return error;
        })
      );
  }

  async getRestaurantProfile() {
    let currentUser = toJS(this.currentUser);
    let defaultRestaurant = toJS(this.defaultRestaurant);

    return client()
      .get(api.restaurantProfile(currentUser?.data?.id, defaultRestaurant?.value))
      .then(
        action('fetchSuccess', data => {
          this.restaurantProfile = data?.data?.data;
          return data;
        }),
        action('fetchError', error => {
          errorHandler({
            title: 'No Information Found',
            body: 'No user associated with this account. If you need assistance please start a live chat or email us at help@hungerhub.com.',
          });
          return error;
        })
      );
  }

  async refreshUserAccount(email, employeeId) {
    let defaultRestaurant = toJS(this.defaultRestaurant);

    return client()
      .get(api.profile(email, employeeId))
      .then(
        action('fetchSuccess', data => {
          this.currentUser = data?.data;
          let restaurantOrganizations = data?.data?.data?.attributes?.employees?.filter(
            employee => employee?.attributes?.organization_type === 'Restaurant'
          );

          let restaurant = restaurantOrganizations.find(function (restaurant, index) {
            if (restaurant.id == defaultRestaurant?.value) return restaurant;
          });

          if (restaurant || restaurantOrganizations?.length > 0) {
            let employeeInfo = restaurant || restaurantOrganizations[0];
            this.setDefaultRestaurant({
              label: employeeInfo?.attributes?.organization_name,
              value: employeeInfo?.id, //id = employee id
              street: employeeInfo?.attributes?.organization_name,
              role: employeeInfo?.attributes?.role,
              timeZone: employeeInfo?.attributes?.tzinfo,
              restaurantId: restaurant?.attributes?.organization_id,
            });
          }

          LocalStorage.setAccessToken(data?.data?.data?.attributes?.access_token);
          return restaurant || restaurantOrganizations?.length > 0;
        }),
        action('fetchError', error => {
          if (error?.response?.status === 401) {
            LocalStorage.removeKey('auth_token');
            this.clearStoredData();
            window.location.reload();
          }
          return error;
        })
      );
  }

  async updateRestaurantProfile(payload) {
    let currentUser = toJS(this.currentUser);
    let defaultRestaurant = toJS(this.defaultRestaurant);

    return client()
      .put(api.restaurantProfile(currentUser?.data?.id, defaultRestaurant?.value), payload)
      .then(
        action('fetchSuccess', data => {
          this.restaurantProfile = data?.data?.data;
          return data;
        }),
        action('fetchError', error => {
          if (error.response?.data?.errors?.includes('already in use')) {
            errorHandler({
              title: 'Phone already taken',
              body: 'This phone number is already in use. If you need assistance please start a live chat or email us at help@hungerhub.com.',
            });
          } else
            errorHandler({
              title: 'No Information Found',
              body: 'No user associated with this account. If you need assistance please start a live chat or email us at help@hungerhub.com.',
            });
          return error;
        })
      );
  }

  async getRestaurantUserProfile() {
    let currentUser = toJS(this.currentUser);
    let defaultRestaurant = toJS(this.defaultRestaurant);

    return client()
      .get(api.userProfile(currentUser?.data?.id, defaultRestaurant?.value))
      .then(
        action('fetchSuccess', data => {
          this.restaurantUser = data?.data?.data;
          return data;
        }),
        action('fetchError', error => {
          errorHandler({
            title: 'No Information Found',
            body: 'No user associated with this account. If you need assistance please start a live chat or email us at help@hungerhub.com.',
          });
          return error;
        })
      );
  }

  async updateUserProfile(payload) {
    let currentUser = toJS(this.currentUser);
    let defaultRestaurant = toJS(this.defaultRestaurant);

    return client()
      .put(api.profile(currentUser?.data?.id, defaultRestaurant?.value), payload)
      .then(
        action('fetchSuccess', data => {
          this.getRestaurantUserProfile();
          this.currentUser = data?.data;
          return data;
        }),
        action('fetchError', error => {
          errorHandler({
            title: 'Invalid Password',
            body: 'The current password entered is incorrect.',
          });
          return error;
        })
      );
  }

  async updateNotificationSettings(payload) {
    let currentUser = toJS(this.currentUser);
    let defaultRestaurant = toJS(this.defaultRestaurant);

    return client()
      .put(api.userProfile(currentUser?.data?.id, defaultRestaurant?.value), payload)
      .then(
        action('fetchSuccess', data => {
          this.restaurantUser = data?.data?.data;
          return data;
        }),
        action('fetchError', error => {
          errorHandler({
            title: 'No Information Found',
            body: 'No user associated with this account. If you need assistance please start a live chat or email us at help@hungerhub.com.',
          });
          return error;
        })
      );
  }

  async ifUserExist(email) {
    let defaultRestaurant = toJS(this.defaultRestaurant);
    return client()
      .get(api.fetchProfile(email, defaultRestaurant?.value))
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          return error;
        })
      );
  }

  async getFornightlyStatus(id, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.fortnightly()}/${id}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.fortnightlyStatus = data?.data;
          return data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error;
        })
      );
  }

  async getReportsHistory(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.report()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.reportHistory = data?.data;
          return data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error;
        })
      );
  }
}

export default UserStore;
