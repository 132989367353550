import React, { useContext, useEffect, useMemo } from 'react';
import { Prepare } from './index';
import { observer } from 'mobx-react';
import { StoreContext } from 'index';
import { toJS } from 'mobx';
import useEmployeeId from 'utils/helpers/user';
import { useQueryMethods } from 'hooks/useQueryMethods';
import object from 'utils/helpers/object';

function OrderDetail(props) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const { defaultRestaurant } = userStore;
  const { getParam, resourceId } = useQueryMethods();
  const { employeeId, timeZone } = new useEmployeeId();
  const orderStore = toJS(store.orderStore);
  let { currentPath, actionCablePayload } = orderStore;

  useEffect(() => {
    window?.fcWidget?.destroy();
    store.orderStore.setCurrentPath({ pathName: '/order' });
    getOrderDetail();

    return () => {
      store.orderStore.setCurrentPath(null);
    };
  }, []);

  const getOrderDetail = () => {
    store.orderStore.getOrdersPrepare({ employee_id: employeeId }, resourceId()).then(response => {
      store.orderStore.getOrderSummaries(
        {
          employee_id: employeeId,
          date: response?.data?.meal_date,
          service: 'uncatering',
        },
        timeZone
      );

      store.orderStore.getHungerhubCateringSummaries({
        employee_id: employeeId,
        date: response?.data?.meal_date,
        service: 'odc',
      });
    });
  };

  useMemo(() => {
    getOrderDetail();
  }, [object.isPresent(actionCablePayload)]);

  return (
    <div className="bg-white text-left min-h-screen d-col">
      <Prepare />
    </div>
  );
}

export default observer(OrderDetail);
