import UserStore from './user';
import OrderStore from './orders';
import MenuStore from './menu';
import ScheduleStore from './schedule';
import NotificationStore from './notifications';
import { create } from 'mobx-persist';

const hydrate = create({
  storage: localStorage,
  jsonify: true,
});

class RootStore {
  UserStore = new UserStore(this);
  OrderStore = new OrderStore(this);
  MenuStore = new MenuStore(this);
  ScheduleStore = new ScheduleStore(this);
  NotificationStore = new NotificationStore(this);

  constructor() {
    // hydrate('Store', this.UserStore);
    // hydrate('Store', this.OrderStore);
    // hydrate('Store', this.MenuStore);
    // hydrate('Store', this.ScheduleStore);
  }
}

export default RootStore;
