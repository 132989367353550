import React from 'react';
import { Logo, LogoMini } from '../../assets/img';
import Dropdown from './Dropdown';
import Notifications from './Notifications';
import { ActionCableWrapper } from 'components/Shared';
import ProgressiveImage from 'react-progressive-image';

export default function Navbar({ fixed }) {
  return (
    <>
      <nav className="flex flex-wrap items-center justify-between px-2 pt-0 bg-white border shadow-md">
        <ActionCableWrapper>
          <div className="w-full flex flex-wrap items-center justify-between">
            <div className="relative d-row justify-between w-full">
              <ProgressiveImage src={Logo} placeholder={LogoMini}>
                {(src, loading) => (
                  <img src={src} alt="hungerhub" className="h-20 w-56 self-start object-contain" />
                )}
              </ProgressiveImage>

              <div className="d-row">
                <Notifications />
                <Dropdown />
              </div>
            </div>
          </div>
        </ActionCableWrapper>
      </nav>
    </>
  );
}
