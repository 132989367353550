import { Summary } from 'containers/DailySummary';
import React from 'react';
import { OrderDetail, CateringOrderDetail } from 'containers/OrderDetail';

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );
    // try to import the component
    componentImport()
      .then(component => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch(error => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

const TeamManagement = React.lazy(() =>
  lazyRetry(() => import('containers/TeamManagement/TeamManagement'))
);
const Accounting = React.lazy(() => lazyRetry(() => import('containers/Accounting/Accounting')));
const Menu = React.lazy(() => lazyRetry(() => import('containers/Menu/Menu')));
const Scheduling = React.lazy(() => lazyRetry(() => import('containers/Scheduling/Scheduling')));
const RestarantProfile = React.lazy(() =>
  lazyRetry(() => import('containers/RestaurantProfile/RestaurantProfile'))
);
const Profile = React.lazy(() => lazyRetry(() => import('containers/Profile/Profile')));
const Notifications = React.lazy(() =>
  lazyRetry(() => import('containers/Notifications/Notifications'))
);
const FAQ = React.lazy(() => lazyRetry(() => import('containers/FAQ/FAQ')));

export default {
  owner: {
    routes: [
      {
        component: Summary,
        url: '/order-summary',
      },
      {
        component: OrderDetail,
        url: '/uncatering-order/:id',
      },
      {
        component: CateringOrderDetail,
        url: '/catering-order/:id',
      },
      {
        component: TeamManagement,
        url: '/team-management',
      },
      {
        component: Accounting,
        url: '/accounting',
      },
      {
        component: Menu,
        url: '/menu',
      },
      {
        component: Scheduling,
        url: '/schedule',
      },
      {
        component: RestarantProfile,
        url: '/restaurant-profile',
      },
      {
        component: Profile,
        url: '/profile',
      },
      {
        component: Notifications,
        url: '/notifications',
      },
      {
        component: FAQ,
        url: '/faq',
      },
    ],
  },
  manager: {
    routes: [
      {
        component: Summary,
        url: '/order-summary',
      },
      {
        component: OrderDetail,
        url: '/uncatering-order/:id',
      },
      {
        component: CateringOrderDetail,
        url: '/catering-order/:id',
      },
      {
        component: TeamManagement,
        url: '/team-management',
      },
      {
        component: Menu,
        url: '/menu',
      },
      {
        component: Scheduling,
        url: '/schedule',
      },
      {
        component: RestarantProfile,
        url: '/restaurant-profile',
      },
      {
        component: Profile,
        url: '/profile',
      },
      {
        component: Notifications,
        url: '/notifications',
      },
      {
        component: FAQ,
        url: '/faq',
      },
    ],
  },
  employee: {
    routes: [
      {
        component: Summary,
        url: '/order-summary',
      },
      {
        component: OrderDetail,
        url: '/uncatering-order/:id',
      },
      {
        component: CateringOrderDetail,
        url: '/catering-order/:id',
      },
      {
        component: Profile,
        url: '/profile',
      },
      {
        component: Notifications,
        url: '/notifications',
      },
      {
        component: FAQ,
        url: '/faq',
      },
    ],
  },
  accounting: {
    routes: [
      {
        component: Accounting,
        url: '/accounting',
      },

      {
        component: Profile,
        url: '/profile',
      },
      {
        component: FAQ,
        url: '/faq',
      },
    ],
  },

  common: {
    routes: [],
  },
};
